import React, { useEffect, useState } from 'react';

import { Button, Form, Input, notification, Space } from 'antd';
import { useNavigate } from 'react-router-dom';

import spotsService, { SpotParams } from '../../../../services/professions/spotsService';
import ErrorsType from '../../../../typings/ErrorsType';
import TextEditor from '../../../layouts/text-editor';

interface Props {
  spotId: number;
  profession: string;
  setLoading: (value: boolean) => void;
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 14 },
};

const SpotForm: React.FC<Props> = ({ profession, spotId, setLoading }) => {
  const [errors, setErrors] = useState<Record<string, Array<string>>>({});

  const [form] = Form.useForm<SpotParams>();
  const navigate = useNavigate();

  useEffect(() => {
    spotsService
      .getSpot(profession, spotId)
      .then((response) => {
        form.setFieldsValue(response.spot);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [form, profession, setLoading, spotId]);

  const catchErrors = (e: ErrorsType) => {
    if (e.statusCode === 422) {
      setErrors(e.errors || {});
    }
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  const onFinish = (values: SpotParams) => {
    setLoading(true);
    setErrors({});

    spotsService
      .updateSpot(profession, spotId, values)
      .then(({ spot }) => {
        notification.success({
          message: 'Zapisano miejsce',
          description: (
            <>
              Dane miejsca <strong>{spot.name_pl}</strong> zostały zaktualizowane.
            </>
          ),
        });
        navigate('../');
      })
      .catch(catchErrors)
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = () => {
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  return (
    <Form
      {...layout}
      form={form}
      name="spotForm"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Nazwa"
        name="name_pl"
        rules={[{ required: true, message: 'Nazwa jest wymagana' }]}
        hasFeedback
        help={errors.name_pl?.[0]}
        validateStatus={errors.name_pl ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Nazwa (EN)"
        name="name_en"
        hasFeedback
        help={errors.name_en?.[0]}
        validateStatus={errors.name_en ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Opis"
        name="description_pl"
        hasFeedback
        help={errors.description_pl?.[0]}
        validateStatus={errors.description_pl ? 'error' : undefined}
      >
        <TextEditor />
      </Form.Item>

      <Form.Item
        label="Opis (EN)"
        name="description_en"
        hasFeedback
        help={errors.description_en?.[0]}
        validateStatus={errors.description_en ? 'error' : undefined}
      >
        <TextEditor />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Space>
          <Button className="mr-2" type="primary" htmlType="submit">
            Zapisz
          </Button>
          <Button className="mr-2" htmlType="button" onClick={() => form.resetFields()}>
            Reset
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
export default SpotForm;
