import { useState } from 'react';

import { Spin } from 'antd';
import { useParams } from 'react-router-dom';

import SpotForm from './spot-form';

import TitledCard from '../../../layout-components/titled-card';

const EditSpot: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const { profession, spotId } = useParams();

  return (
    <Spin spinning={loading}>
      <TitledCard title="Edycja miejsca" backUrl="..">
        <SpotForm profession={profession ?? ''} spotId={Number(spotId)} setLoading={setLoading} />
      </TitledCard>
    </Spin>
  );
};
export default EditSpot;
