import { serialize } from 'object-to-formdata';

import type PictureType from '../../typings/legends/PictureType';

import { deleteRequest, getRequest, postRequest, putRequest } from '../../api/api-client';

interface PicturesResponse {
  pictures: Array<PictureType>;
}

interface PictureResponse {
  picture: PictureType;
}

export interface PictureParams {
  duration: number;
  image_pl?: File;
  image_en?: File;
}

const getPictures = async (): Promise<PicturesResponse> => {
  return getRequest(`/legends/pictures`);
};

const getPicture = async (pictureId: number): Promise<PictureResponse> => {
  return getRequest(`/legends/pictures/${pictureId}`);
};

const createPicture = async (params: PictureParams): Promise<PictureResponse> => {
  return postRequest(`/legends/pictures`, serialize({ picture: params }));
};

const updatePicture = async (
  pictureId: number,
  params: PictureParams,
): Promise<PictureResponse> => {
  return putRequest(`/legends/pictures/${pictureId}`, serialize({ picture: params }));
};
const deletePicture = async (pictureId: number): Promise<void> => {
  return deleteRequest(`/legends/pictures/${pictureId}`);
};

const reorderPictures = async (pictures: Array<{ id: number }>): Promise<PicturesResponse> => {
  return postRequest(`/legends/pictures/reorder`, { pictures });
};

const picturesService = {
  getPictures,
  getPicture,
  createPicture,
  updatePicture,
  deletePicture,
  reorderPictures,
};

export default picturesService;
