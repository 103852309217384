import { useEffect, useState } from 'react';

import { Button, Form, Input, notification, Select, Space } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useNavigate } from 'react-router-dom';

import type CuisineType from '../../../../typings/cookbook/CuisineType';
import type ErrorsType from '../../../../typings/ErrorsType';

import cuisinesService from '../../../../services/cookbook/cuisines-service';
import recipesService, { RecipeParams } from '../../../../services/cookbook/recipes-service';
import RecipeType from '../../../../typings/cookbook/RecipeType';
import TextEditor from '../../../layouts/text-editor';
import FileInput from '../../../utils/file-input';

interface Props {
  recipeId?: number;
  setLoading: (value: boolean) => void;
}

const { Option } = Select;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 14 },
};

const RecipeForm: React.FC<Props> = ({ recipeId, setLoading }) => {
  const [cuisines, setCuisines] = useState<Array<CuisineType>>([]);
  const [recipe, setRecipe] = useState<RecipeType>();
  const [errors, setErrors] = useState<Record<string, Array<string>>>({});

  const [form] = Form.useForm<RecipeParams>();
  const navigate = useNavigate();

  useEffect(() => {
    cuisinesService.getCuisines().then((response) => {
      setCuisines(response.cuisines);
    });
    if (recipeId) {
      recipesService.getRecipe(recipeId).then((response) => {
        form.setFieldsValue({ ...response.recipe, cuisine_id: response.recipe.cuisine?.id });
        setRecipe(response.recipe);
        setLoading(false);
      });
    } else {
      setRecipe(undefined);
    }
  }, [form, recipeId, setLoading]);

  const catchErrors = (e: ErrorsType) => {
    if (e.statusCode === 422) {
      setErrors(e.errors || {});
    }
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  const onFinish = (values: RecipeParams) => {
    setLoading(true);
    setErrors({});

    if (recipeId) {
      recipesService
        .updateRecipe(recipeId, values)
        .then((response) => {
          notification.success({
            message: 'Zapisano przepis',
            description: (
              <>
                Dane przepisu <strong>{response.recipe.name_pl}</strong> zostały zaktualizowane.
              </>
            ),
          });
          navigate('../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    } else {
      recipesService
        .createRecipe(values)
        .then((response) => {
          notification.success({
            message: 'Utworzono przepis',
            description: (
              <>
                Nowy przepis <strong>{response.recipe.name_pl}</strong> został dodany.
              </>
            ),
          });
          navigate('../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onFinishFailed = () => {
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  return (
    <Form
      {...layout}
      form={form}
      name="newRecipeForm"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Nazwa"
        name="name_pl"
        rules={[{ required: true, message: 'Nazwa jest wymagana' }]}
        hasFeedback
        help={errors.name_pl?.[0]}
        validateStatus={errors.name_pl ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Nazwa (EN)"
        name="name_en"
        rules={[{ required: true, message: 'Nazwa jest wymagana' }]}
        hasFeedback
        help={errors.name_en?.[0]}
        validateStatus={errors.name_en ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Kuchnia"
        name="cuisine_id"
        rules={[{ required: true, message: 'Kuchnia jest wymagana' }]}
        hasFeedback
        help={errors.cuisine_id?.[0]}
        validateStatus={errors.cuisine_id ? 'error' : undefined}
      >
        <Select>
          {cuisines.map((cuisine) => (
            <Option key={cuisine.id} value={cuisine.id}>
              {cuisine.name_pl}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Zdjęcie"
        name="cover_image"
        hasFeedback
        help={errors.cover_image?.[0] || 'Sugerowana maksymalna szerokość zdjęcia: 1650px'}
        validateStatus={errors.cover_image ? 'error' : undefined}
      >
        <FileInput
          accept="image/jpeg,image/png"
          maxCount={1}
          defaultPreview={recipe?.thumb_cover_image_url}
        />
      </Form.Item>

      <Form.Item
        label="Składniki"
        name="ingredients_pl"
        hasFeedback
        help={errors.ingredients_pl?.[0]}
        validateStatus={errors.ingredients_pl ? 'error' : undefined}
      >
        <TextArea rows={6} />
      </Form.Item>

      <Form.Item
        label="Składniki (EN)"
        name="ingredients_en"
        hasFeedback
        help={errors.ingredients_en?.[0]}
        validateStatus={errors.ingredients_en ? 'error' : undefined}
      >
        <TextArea rows={6} />
      </Form.Item>

      <Form.Item
        label="Treść przepisu"
        name="content_pl"
        hasFeedback
        help={errors.content_pl?.[0]}
        validateStatus={errors.content_pl ? 'error' : undefined}
      >
        <TextEditor />
      </Form.Item>

      <Form.Item
        label="Treść przepisu (EN)"
        name="content_en"
        hasFeedback
        help={errors.content_en?.[0]}
        validateStatus={errors.content_en ? 'error' : undefined}
      >
        <TextEditor />
      </Form.Item>

      <Form.Item
        label="Liczba osób"
        name="persons"
        hasFeedback
        help={errors.persons?.[0]}
        validateStatus={errors.persons ? 'error' : undefined}
      >
        <Input type="number" min={1} />
      </Form.Item>

      <Form.Item
        label="Czas przygotowania"
        name="preparation_time_pl"
        hasFeedback
        help={errors.preparation_time_pl?.[0]}
        validateStatus={errors.preparation_time_pl ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Czas przygotowania (EN)"
        name="preparation_time_en"
        hasFeedback
        help={errors.preparation_time_en?.[0]}
        validateStatus={errors.preparation_time_en ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Zdjęcie dodatkowe"
        name="image"
        hasFeedback
        help={errors.image?.[0] || 'Sugerowana maksymalna szerokość zdjęcia: 1040px'}
        validateStatus={errors.image ? 'error' : undefined}
      >
        <FileInput
          accept="image/jpeg,image/png"
          maxCount={1}
          defaultPreview={recipe?.thumb_image_url}
        />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Space>
          <Button className="mr-2" type="primary" htmlType="submit">
            Zapisz
          </Button>
          <Button className="mr-2" htmlType="button" onClick={() => form.resetFields()}>
            Reset
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
export default RecipeForm;
