import { useEffect, useState } from 'react';

import { Spin } from 'antd';
import { useParams } from 'react-router-dom';

import PictureForm from './picture-form';

import type PhotographerType from '../../../../../typings/photoBooth/PhotographerType';

import photographersService from '../../../../../services/photoBooth/photographers-service';
import TitledCard from '../../../../layout-components/titled-card';

const NewPicture: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [photographer, setPhotographer] = useState<PhotographerType>();
  const params = useParams();
  const photographerId = Number(params.photographerId);

  useEffect(() => {
    photographersService.getPhotographer(photographerId).then((response) => {
      setPhotographer(response.photographer);
    });
  }, [photographerId]);

  return (
    <Spin spinning={loading}>
      <TitledCard title={`Fotograf ${photographer?.title_pl} - Nowe zdjęcie`} backUrl="..">
        <PictureForm setLoading={setLoading} photographerId={photographerId} />
      </TitledCard>
    </Spin>
  );
};
export default NewPicture;
