import { serialize } from 'object-to-formdata';

import type RecipeType from '../../typings/pharmacy/RecipeType';

import { deleteRequest, getRequest, postRequest, putRequest } from '../../api/api-client';

interface RecipesResponse {
  recipes: Array<RecipeType>;
}

interface RecipeResponse {
  recipe: RecipeType;
}

export interface RecipeParams {
  name_pl: string;
  name_en: string;
  disease_pl: string;
  disease_en: string;
  ingredients_pl: string;
  ingredients_en: string;
  content_pl: string;
  content_en: string;
  persons: number;
  preparation_time_pl: string;
  preparation_time_en: string;
  cover_image: File | null;
}

const getRecipes = async (): Promise<RecipesResponse> => {
  return getRequest('/pharmacy/recipes');
};

const getRecipe = async (recipeId: number): Promise<RecipeResponse> => {
  return getRequest(`/pharmacy/recipes/${recipeId}`);
};

const createRecipe = async (params: RecipeParams): Promise<RecipeResponse> => {
  return postRequest(`/pharmacy/recipes`, serialize({ recipe: params }));
};

const updateRecipe = async (recipeId: number, params: RecipeParams): Promise<RecipeResponse> => {
  return putRequest(`/pharmacy/recipes/${recipeId}`, serialize({ recipe: params }));
};
const deleteRecipe = async (recipeId: number): Promise<void> => {
  return deleteRequest(`/pharmacy/recipes/${recipeId}`);
};

const recipesService = {
  getRecipes,
  getRecipe,
  createRecipe,
  updateRecipe,
  deleteRecipe,
};

export default recipesService;
