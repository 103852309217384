import { serialize } from 'object-to-formdata';

import type DialogueType from '../../typings/language/DialogueType';

import { deleteRequest, getRequest, postRequest, putRequest } from '../../api/api-client';

interface DialoguesResponse {
  dialogues: Array<DialogueType>;
}

interface DialogueResponse {
  dialogue: DialogueType;
}

export interface DialogueParams {
  title_pl: string;
  title_en: string;
  description_pl: string;
  description_en: string;
  audio: File | null;
}

const getDialogues = async (): Promise<DialoguesResponse> => {
  return getRequest('/language/dialogues');
};

const getDialogue = async (dialogueId: number): Promise<DialogueResponse> => {
  return getRequest(`/language/dialogues/${dialogueId}`);
};

const createDialogue = async (params: DialogueParams): Promise<DialogueResponse> => {
  return postRequest(`/language/dialogues`, serialize({ dialogue: params }));
};

const updateDialogue = async (
  dialogueId: number,
  params: DialogueParams,
): Promise<DialogueResponse> => {
  return putRequest(`/language/dialogues/${dialogueId}`, serialize({ dialogue: params }));
};
const deleteDialogue = async (dialogueId: number): Promise<void> => {
  return deleteRequest(`/language/dialogues/${dialogueId}`);
};

const reorderDialogues = async (dialogues: Array<{ id: number }>): Promise<DialoguesResponse> => {
  return postRequest('/language/dialogues/reorder', { dialogues });
};

const dialoguesService = {
  getDialogues,
  getDialogue,
  createDialogue,
  updateDialogue,
  deleteDialogue,
  reorderDialogues,
};

export default dialoguesService;
