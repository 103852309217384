import { useEffect, useState } from 'react';

import { Spin } from 'antd';
import { useParams } from 'react-router-dom';

import PictureForm from './picture-form';

import type PhotographerType from '../../../../../typings/photoBooth/PhotographerType';

import photographersService from '../../../../../services/photoBooth/photographers-service';
import TitledCard from '../../../../layout-components/titled-card';

const EditPicture: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [photographer, setPhotographer] = useState<PhotographerType>();

  const params = useParams();
  const photographerId = Number(params.photographerId);
  const pictureId = Number(params.pictureId);

  useEffect(() => {
    photographersService.getPhotographer(photographerId).then((response) => {
      setPhotographer(response.photographer);
    });
  }, [photographerId, pictureId]);

  return (
    <Spin spinning={loading}>
      <TitledCard title={`Fotograf ${photographer?.title_pl} - Edycja zdjęcia`} backUrl="..">
        <PictureForm
          photographerId={photographerId}
          pictureId={pictureId}
          setLoading={setLoading}
        />
      </TitledCard>
    </Spin>
  );
};
export default EditPicture;
