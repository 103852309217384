import { serialize } from 'object-to-formdata';

import type PathPointType from '../../typings/map/PathPointType';

import { deleteRequest, getRequest, postRequest, putRequest } from '../../api/api-client';

interface PathPointsResponse {
  path_points: Array<PathPointType>;
}

interface PathPointResponse {
  path_point: PathPointType;
}

export interface PathPointParams {
  name_pl: string;
  name_en: string;
  description_pl: string;
  description_en: string;
  latitude: number | undefined;
  longitude: number | undefined;
  image: File | null;
}

const getPathPoints = async (pathId: number): Promise<PathPointsResponse> => {
  return getRequest(`/map/paths/${pathId}/points`);
};

const getPathPoint = async (pathId: number, pathPointId: number): Promise<PathPointResponse> => {
  return getRequest(`/map/paths/${pathId}/points/${pathPointId}`);
};

const createPathPoint = async (
  pathId: number,
  params: PathPointParams,
): Promise<PathPointResponse> => {
  return postRequest(`/map/paths/${pathId}/points`, serialize({ path_point: params }));
};

const updatePathPoint = async (
  pathId: number,
  pathPointId: number,
  params: PathPointParams,
): Promise<PathPointResponse> => {
  return putRequest(
    `/map/paths/${pathId}/points/${pathPointId}`,
    serialize({ path_point: params }),
  );
};
const deletePathPoint = async (pathId: number, pathPointId: number): Promise<void> => {
  return deleteRequest(`/map/paths/${pathId}/points/${pathPointId}`);
};

const reorderPathPoints = async (
  pathId: number,
  pathPointIds: Array<{ id: number }>,
): Promise<PathPointsResponse> => {
  return postRequest(`/map/paths/${pathId}/points/reorder`, { path_points: pathPointIds });
};

const pathPointsService = {
  getPathPoints,
  getPathPoint,
  createPathPoint,
  updatePathPoint,
  deletePathPoint,
  reorderPathPoints,
};

export default pathPointsService;
