import { useCallback, useEffect, useState } from 'react';

import { MenuOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Table, notification, Button, Divider, Popconfirm } from 'antd';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Link } from 'react-router-dom';

import dialoguesService from '../../../../services/language/dialogues-service';
import DialogueType from '../../../../typings/language/DialogueType';
import TitledCard from '../../../layout-components/titled-card';
import Flex from '../../../layouts/flex';
import DraggableBodyRow from '../../../utils/draggable-body-row';

const DialoguesList: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [dialogues, setDialogues] = useState<Array<DialogueType>>([]);

  const reloadList = () => {
    dialoguesService.getDialogues().then((response) => {
      setDialogues(response.dialogues);
      setLoading(false);
    });
  };

  useEffect(reloadList, []);

  const removeItem = useCallback((record: DialogueType) => {
    setLoading(true);
    dialoguesService
      .deleteDialogue(record.id)
      .then(() => {
        notification.success({
          message: 'Usunięto dialog',
          description: (
            <>
              Dialog <strong>{record.title_pl}</strong> został usunięty.
            </>
          ),
        });
        reloadList();
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      width: 50,
      className: 'drag-visible',
      render: () => <MenuOutlined />,
      align: 'center' as const,
    },
    {
      title: 'Tytuł',
      dataIndex: 'title_pl',
      render: (title_pl: string, record: DialogueType) => (
        <Link to={`${record.id}/edit`}>{title_pl}</Link>
      ),
    },
    {
      title: 'Tytuł (EN)',
      dataIndex: 'title_en',
    },
    {
      title: 'Frazy',
      key: 'sentences_btn',
      width: 200,
      align: 'center' as const,
      render: (_text: string, record: DialogueType) => (
        <span>
          <Link to={`${record.id}/sentences`}>
            <Button type="primary" size="small">
              Frazy
            </Button>
          </Link>
        </span>
      ),
    },
    {
      title: 'Operacje',
      key: 'action',
      width: 200,
      align: 'center' as const,
      render: (_text: string, record: DialogueType) => (
        <span>
          <Link to={`${record.id}/edit`}>
            <Button type="primary" size="small">
              Edycja
            </Button>
          </Link>

          <Divider type="vertical" />

          <Popconfirm
            title={
              <>
                Czy na pewno usunąć dialog <strong>{record.title_pl}</strong>?
              </>
            }
            onConfirm={() => removeItem(record)}
            okText="Tak"
            cancelText="Nie"
          >
            <Button type="primary" size="small" danger>
              Usuń
            </Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = dialogues[dragIndex];
      const newDialogues = update(dialogues, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });

      setLoading(true);
      dialoguesService
        .reorderDialogues(newDialogues.map((dialogue) => ({ id: dialogue.id })))
        .then((response) => {
          setDialogues(response.dialogues);
          setLoading(false);
        });
    },
    [dialogues],
  );

  return (
    <TitledCard title="Lista dialogów">
      <Flex className="mb-3" alignItems="center" justifyContent="end" mobileFlex={false}>
        <div>
          <Link to="new">
            <Button type="primary" icon={<PlusCircleOutlined />} block>
              Dodaj dialog
            </Button>
          </Link>
        </div>
      </Flex>

      <DndProvider backend={HTML5Backend}>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={dialogues}
          bordered
          loading={loading}
          pagination={false}
          components={components}
          // @ts-ignore
          onRow={(_record, index) => ({
            index,
            moveRow,
          })}
        />
      </DndProvider>
    </TitledCard>
  );
};
export default DialoguesList;
