import { serialize } from 'object-to-formdata';

import type StaticPage from '../../typings/StaticPage';

import { deleteRequest, getRequest, postRequest, putRequest } from '../../api/api-client';

interface StaticPagesResponse {
  static_pages: Array<StaticPage>;
}

interface StaticPageResponse {
  static_page: StaticPage;
}

export interface LocationParams {
  title_pl: string;
  title_en: string;
  slug: string;
  cover_image: File | null;
  sections: Array<
    | {
        id: number;
        title_pl: string;
        title_en: string;
        content_pl: string;
        content_en: string;
        image: File | null;
        image_description_pl: string;
        image_description_en: string;
      }
    | {
        id: number;
        _destroy?: boolean;
      }
  >;
}

const getLocations = async (): Promise<StaticPagesResponse> => {
  return getRequest('/static-pages/industry');
};

const getLocation = async (locationSlug: string): Promise<StaticPageResponse> => {
  return getRequest(`/static-pages/industry/${locationSlug}`);
};

const createLocation = async (params: LocationParams): Promise<StaticPageResponse> => {
  return postRequest('/static-pages/industry', serialize({ static_page: params }));
};

const updateLocation = async (
  locationSlug: string,
  params: LocationParams,
): Promise<StaticPageResponse> => {
  return putRequest(`/static-pages/industry/${locationSlug}`, serialize({ static_page: params }));
};

const deleteLocation = async (locationSlug: string): Promise<void> => {
  return deleteRequest(`/pharmacy/industry/${locationSlug}`);
};
const locationService = {
  getLocations,
  getLocation,
  createLocation,
  updateLocation,
  deleteLocation,
};

export default locationService;
