import { SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from '../../constants/themeConstants';
import { useAppSelector } from '../../redux/hooks';

import './logo.scss';

interface Props {
  isMobile: boolean;
}

const Logo: React.FC<Props> = ({ isMobile }) => {
  const { navCollapsed } = useAppSelector((state) => state.theme);

  const getLogoWidthGutter = () => {
    if (isMobile) {
      return 0;
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    }
    return `${SIDE_NAV_WIDTH}px`;
  };

  return (
    <div className="logo" style={{ width: getLogoWidthGutter() }}>
      <img src="/logo.png" alt="Logo" />
    </div>
  );
};
export default Logo;
