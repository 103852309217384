import { serialize } from 'object-to-formdata';

import type MovieType from '../typings/MovieType';

import { deleteRequest, getRequest, postRequest, putRequest } from '../api/api-client';

interface MoviesResponse {
  movies: Array<MovieType>;
}

interface MovieResponse {
  movie: MovieType;
}

export interface MovieParams {
  name_pl: string;
  name_en: string;
  image: File | null;
  video_pl: File | null;
  video_en: File | null;
  enabled: boolean;
}

const getMovies = async (): Promise<MoviesResponse> => {
  return getRequest('/movies/development');
};

const getMovie = async (movieId: number): Promise<MovieResponse> => {
  return getRequest(`/movies/development/${movieId}`);
};

const createMovie = async (params: MovieParams): Promise<MovieResponse> => {
  return postRequest('/movies/development', serialize({ movie: params }));
};

const updateMovie = async (movieId: number, params: MovieParams): Promise<MovieResponse> => {
  return putRequest(`/movies/development/${movieId}`, serialize({ movie: params }));
};

const deleteMovie = async (movieId: number): Promise<void> => {
  return deleteRequest(`/movies/development/${movieId}`);
};

const developmentService = {
  getMovies,
  getMovie,
  createMovie,
  updateMovie,
  deleteMovie,
};

export default developmentService;
