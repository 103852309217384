import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Header } from 'antd/lib/layout/layout';

import Logo from './logo';
import NavProfile from './nav-profile';

import type EmployeeType from '../../typings/EmployeeType';

import { SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from '../../constants/themeConstants';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { toggleCollapsedNav, toggleMobileNav } from '../../redux/slices/themeSlice';
import Icon from '../layouts/icon';

interface Props {
  isMobile: boolean;
  employee?: EmployeeType;
}

const NavHeader: React.FC<Props> = ({ isMobile, employee }) => {
  const { navCollapsed, mobileNav } = useAppSelector((state) => state.theme);
  const dispatch = useAppDispatch();

  const getNavWidth = () => {
    if (isMobile) {
      return '0px';
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    }
    return `${SIDE_NAV_WIDTH}px`;
  };

  const onToggle = () => {
    if (isMobile) {
      dispatch(toggleMobileNav(!mobileNav));
    } else {
      dispatch(toggleCollapsedNav(!navCollapsed));
    }
  };

  return (
    <Header className="app-header">
      <div className="app-header-wrapper">
        <Logo isMobile={isMobile} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              <li className="ant-menu-item ant-menu-item-only-child">
                <button type="button" onClick={onToggle} className="app-button">
                  <Icon
                    type={navCollapsed || isMobile ? MenuUnfoldOutlined : MenuFoldOutlined}
                    className="nav-icon"
                  />
                </button>
              </li>
            </ul>
          </div>
          <div className="nav-right">{employee ? <NavProfile employee={employee} /> : null}</div>
        </div>
      </div>
    </Header>
  );
};

export default NavHeader;
