import type EmployeeType from '../typings/EmployeeType';

import { deleteRequest, getRequest, postRequest, putRequest } from '../api/api-client';

interface EmployeesResponse {
  employees: Array<EmployeeType>;
}

interface EmployeeResponse {
  employee: EmployeeType;
}

export interface EmployeeParams {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
}

const getEmployees = async (): Promise<EmployeesResponse> => {
  return getRequest('/employees');
};

const getEmployee = async (employeeId: number): Promise<EmployeeResponse> => {
  return getRequest(`/employees/${employeeId}`);
};

const createEmployee = async (params: EmployeeParams): Promise<EmployeeResponse> => {
  return postRequest(`/employees`, { employee: params });
};

const updateEmployee = async (
  employeeId: number,
  params: EmployeeParams,
): Promise<EmployeeResponse> => {
  return putRequest(`/employees/${employeeId}`, { employee: params });
};
const deleteEmployee = async (employeeId: number): Promise<void> => {
  return deleteRequest(`/employees/${employeeId}`);
};

const EmployeesService = {
  getEmployees,
  getEmployee,
  createEmployee,
  updateEmployee,
  deleteEmployee,
};

export default EmployeesService;
