import { useState } from 'react';

import { Spin } from 'antd';
import { useParams } from 'react-router-dom';

import RecipeForm from './recipe-form';

import TitledCard from '../../../layout-components/titled-card';

const EditRecipe: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const params = useParams<{ recipeId: string }>();
  const recipeId = Number(params.recipeId);

  return (
    <Spin spinning={loading}>
      <TitledCard title="Edycja przepisu" backUrl="..">
        <RecipeForm recipeId={recipeId} setLoading={setLoading} />
      </TitledCard>
    </Spin>
  );
};
export default EditRecipe;
