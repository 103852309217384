import { useState } from 'react';

import { Spin } from 'antd';

import LocationForm from './location-form';

import TitledCard from '../../../layout-components/titled-card';

const NewLocation: React.FC = () => {
  const [loading, setLoading] = useState(false);

  return (
    <Spin spinning={loading}>
      <TitledCard title="Nowe miejsce" backUrl="..">
        <LocationForm setLoading={setLoading} />
      </TitledCard>
    </Spin>
  );
};
export default NewLocation;
