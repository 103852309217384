import { useCallback, useEffect, useState } from 'react';

import { PlusCircleOutlined } from '@ant-design/icons';
import { Table, notification, Button, Divider, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';

import developmentService from '../../../services/developmentService';
import MovieType from '../../../typings/MovieType';
import TitledCard from '../../layout-components/titled-card';
import Flex from '../../layouts/flex';

const MoviesList: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [movies, setMovies] = useState<Array<MovieType>>([]);

  const reloadList = () => {
    developmentService.getMovies().then((response) => {
      setMovies(response.movies);
      setLoading(false);
    });
  };

  useEffect(reloadList, []);

  const removeItem = useCallback((record: MovieType) => {
    setLoading(true);
    developmentService
      .deleteMovie(record.id)
      .then(() => {
        notification.success({
          message: 'Usunięto film',
          description: (
            <>
              Film <strong>{record.name_pl}</strong> został usunięty.
            </>
          ),
        });
        reloadList();
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const columns = [
    {
      title: 'Nazwa',
      dataIndex: 'name_pl',
      render: (name: string, record: MovieType) => <Link to={`${record.id}/edit`}>{name}</Link>,
    },
    {
      title: 'Nazwa (EN)',
      dataIndex: 'name_en',
    },
    {
      title: 'Zdjęcie',
      dataIndex: 'image_url',
      render: (imageUrl: string | null, record: MovieType) =>
        imageUrl ? <img src={imageUrl} alt={record.name_pl} /> : null,
      align: 'center' as const,
      width: 200,
    },
    {
      title: 'Operacje',
      key: 'action',
      width: 200,
      align: 'center' as const,
      render: (_text: string, record: MovieType) => (
        <span>
          <Link to={`${record.id}/edit`}>
            <Button type="primary" size="small">
              Edycja
            </Button>
          </Link>

          <Divider type="vertical" />

          <Popconfirm
            title={
              <>
                Czy na pewno usunąć film <strong>{record.name_pl}</strong>?
              </>
            }
            onConfirm={() => removeItem(record)}
            okText="Tak"
            cancelText="Nie"
          >
            <Button type="primary" size="small" danger>
              Usuń
            </Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <TitledCard title="Lista filmów">
      <Flex className="mb-3" alignItems="center" justifyContent="end" mobileFlex={false}>
        <div>
          <Link to="new">
            <Button type="primary" icon={<PlusCircleOutlined />} block>
              Dodaj film
            </Button>
          </Link>
        </div>
      </Flex>
      <Table
        rowKey="id"
        dataSource={movies}
        columns={columns}
        bordered
        loading={loading}
        pagination={false}
      />
    </TitledCard>
  );
};
export default MoviesList;
