import { useState } from 'react';

import { Spin } from 'antd';
import { useParams } from 'react-router-dom';

import LocationForm from './location-form';

import TitledCard from '../../../layout-components/titled-card';

const EditLocation: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const { locationSlug } = useParams();

  return (
    <Spin spinning={loading}>
      <TitledCard title="Edycja miejsca" backUrl="..">
        <LocationForm locationSlug={locationSlug} setLoading={setLoading} />
      </TitledCard>
    </Spin>
  );
};
export default EditLocation;
