/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    navCollapsed: false,
    mobileNav: false,
  },
  reducers: {
    toggleCollapsedNav: (state, action: { payload: boolean }) => {
      state.navCollapsed = action.payload;
    },
    toggleMobileNav: (state, action: { payload: boolean }) => {
      state.mobileNav = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleCollapsedNav, toggleMobileNav } = themeSlice.actions;

export default themeSlice.reducer;
