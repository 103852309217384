import { useCallback, useEffect, useState } from 'react';

import { PlusCircleOutlined } from '@ant-design/icons';
import { Table, notification, Button, Divider, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';

import type CuisineType from '../../../../typings/cookbook/CuisineType';
import type RecipeType from '../../../../typings/cookbook/RecipeType';

import recipesService from '../../../../services/cookbook/recipes-service';
import TitledCard from '../../../layout-components/titled-card';
import Flex from '../../../layouts/flex';

const RecipesList: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [recipes, setrecipes] = useState<Array<RecipeType>>([]);

  const reloadList = () => {
    recipesService.getRecipes().then((response) => {
      setrecipes(response.recipes);
      setLoading(false);
    });
  };

  useEffect(reloadList, []);

  const removeItem = useCallback((record: RecipeType) => {
    setLoading(true);
    recipesService
      .deleteRecipe(record.id)
      .then(() => {
        notification.success({
          message: 'Usunięto przepis',
          description: (
            <>
              Przepis <strong>{record.name_pl}</strong> został usunięty.
            </>
          ),
        });
        reloadList();
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const columns = [
    {
      title: 'Nazwa',
      dataIndex: 'name_pl',
      render: (name_pl: string, record: RecipeType) => (
        <Link to={`${record.id}/edit`}>{name_pl}</Link>
      ),
    },
    {
      title: 'Kuchnia',
      dataIndex: 'cuisine',
      render: (cuisine: CuisineType) => cuisine.name_pl,
      align: 'center' as const,
      width: 250,
    },
    {
      title: 'Osoby',
      dataIndex: 'persons',
      align: 'center' as const,
      width: 150,
    },
    {
      title: 'Zdjęcie okładki',
      dataIndex: 'thumb_cover_image_url',
      render: (imageUrl: string | null, record: RecipeType) =>
        imageUrl ? <img src={imageUrl} alt={record.name_pl} /> : null,
      align: 'center' as const,
      width: 200,
    },
    {
      title: 'Zdjęcie dodatkowe',
      dataIndex: 'thumb_image_url',
      render: (imageUrl: string | null, record: RecipeType) =>
        imageUrl ? <img src={imageUrl} alt={record.name_pl} /> : null,
      align: 'center' as const,
      width: 200,
    },
    {
      title: 'Operacje',
      key: 'action',
      width: 200,
      align: 'center' as const,
      render: (_text: string, record: RecipeType) => (
        <span>
          <Link to={`${record.id}/edit`}>
            <Button type="primary" size="small">
              Edycja
            </Button>
          </Link>

          <Divider type="vertical" />

          <Popconfirm
            title={
              <>
                Czy na pewno usunąć przepis <strong>{record.name_pl}</strong>?
              </>
            }
            onConfirm={() => removeItem(record)}
            okText="Tak"
            cancelText="Nie"
          >
            <Button type="primary" size="small" danger>
              Usuń
            </Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <TitledCard title="Lista przepisów">
      <Flex className="mb-3" alignItems="center" justifyContent="end" mobileFlex={false}>
        <div>
          <Link to="new">
            <Button type="primary" icon={<PlusCircleOutlined />} block>
              Dodaj przepis
            </Button>
          </Link>
        </div>
      </Flex>
      <Table
        rowKey="id"
        dataSource={recipes}
        columns={columns}
        bordered
        loading={loading}
        pagination={false}
      />
    </TitledCard>
  );
};
export default RecipesList;
