import { serialize } from 'object-to-formdata';

import type PictureType from '../typings/PictureType';

import { deleteRequest, getRequest, postRequest, putRequest } from '../api/api-client';

interface PicturesResponse {
  pictures: Array<PictureType>;
}

interface PictureResponse {
  picture: PictureType;
}

export interface PictureParams {
  description_pl?: string;
  description_en?: string;
  image: File;
}

const getPictures = async (): Promise<PicturesResponse> => {
  return getRequest('/pictures');
};

const getPicture = async (pictureId: number): Promise<PictureResponse> => {
  return getRequest(`/pictures/${pictureId}`);
};

const createPicture = async (params: PictureParams): Promise<PictureResponse> => {
  const { image, ...formParams } = params;
  const formData = serialize(formParams, {}, undefined, 'picture');
  if (image) {
    formData.append('picture[image]', image, image.name);
  }
  return postRequest(`/pictures`, formData);
};

const updatePicture = async (
  pictureId: number,
  params: PictureParams,
): Promise<PictureResponse> => {
  const formData = serialize(params, {}, undefined, 'picture');
  return putRequest(`/pictures/${pictureId}`, formData);
};
const deletePicture = async (pictureId: number): Promise<void> => {
  return deleteRequest(`/pictures/${pictureId}`);
};

const reorderPictures = async (pictures: Array<{ id: number }>): Promise<PicturesResponse> => {
  return postRequest('/pictures/reorder', { pictures });
};

const PicturesService = {
  getPictures,
  getPicture,
  createPicture,
  updatePicture,
  deletePicture,
  reorderPictures,
};

export default PicturesService;
