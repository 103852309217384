import { serialize } from 'object-to-formdata';

import type PictureType from '../../typings/map/PictureType';

import { deleteRequest, getRequest, postRequest, putRequest } from '../../api/api-client';

interface PicturesResponse {
  pictures: Array<PictureType>;
}

interface PictureResponse {
  picture: PictureType;
}

export interface PictureParams {
  description_pl: string;
  description_en: string;
  image?: File;
}

const getPictures = async (personId: number): Promise<PicturesResponse> => {
  return getRequest(`/photobooth/photographers/${personId}/pictures`);
};

const getPicture = async (personId: number, pictureId: number): Promise<PictureResponse> => {
  return getRequest(`/photobooth/photographers/${personId}/pictures/${pictureId}`);
};

const createPicture = async (personId: number, params: PictureParams): Promise<PictureResponse> => {
  return postRequest(
    `/photobooth/photographers/${personId}/pictures`,
    serialize({ picture: params }),
  );
};

const updatePicture = async (
  personId: number,
  pictureId: number,
  params: PictureParams,
): Promise<PictureResponse> => {
  return putRequest(
    `/photobooth/photographers/${personId}/pictures/${pictureId}`,
    serialize({ picture: params }),
  );
};
const deletePicture = async (personId: number, pictureId: number): Promise<void> => {
  return deleteRequest(`/photobooth/photographers/${personId}/pictures/${pictureId}`);
};

const reorderPictures = async (
  personId: number,
  pictures: Array<{ id: number }>,
): Promise<PicturesResponse> => {
  return postRequest(`/photobooth/photographers/${personId}/pictures/reorder`, { pictures });
};

const picturesService = {
  getPictures,
  getPicture,
  createPicture,
  updatePicture,
  deletePicture,
  reorderPictures,
};

export default picturesService;
