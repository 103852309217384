import React, { useEffect, useState } from 'react';

import { Button, Form, Input, notification, Space, Spin } from 'antd';

import descriptionService, {
  DescriptionParams,
} from '../../../services/industry/description-service';
import ErrorsType from '../../../typings/ErrorsType';
import TitledCard from '../../layout-components/titled-card';
import TextEditor from '../../layouts/text-editor';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 14 },
};

const DescriptionForm: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm<DescriptionParams>();
  const [errors, setErrors] = useState<Record<string, Array<string>>>({});

  useEffect(() => {
    descriptionService
      .getDescription()
      .then((response) => {
        form.setFieldsValue(response.static_page?.sections?.[0]);
      })
      .catch((e: ErrorsType) => {
        if (e.statusCode === 404) {
          form.setFieldsValue({});
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [form]);

  const onFinish = (values: DescriptionParams) => {
    setLoading(true);
    setErrors({});

    descriptionService
      .updateDescription(values)
      .then((response) => {
        notification.success({
          message: 'Zapisano opis strony',
          description: (
            <>
              Opis strony <strong>Wstęp</strong> został zaktualizowany.
            </>
          ),
        });

        form.setFieldsValue(response.static_page?.sections?.[0]);
        setLoading(false);
      })
      .catch((e: ErrorsType) => {
        if (e.statusCode === 422) {
          setErrors(e.errors || {});
        } else {
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw e;
        }
        notification.error({
          message: 'Dane nie mogły być zapisane',
        });
      });
  };

  const onFinishFailed = () => {
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  return (
    <Spin spinning={loading}>
      <TitledCard title='Edycja podstrony "Wstęp"'>
        <Form
          {...layout}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item name="id" noStyle>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item
            label="Treść podstrony (PL)"
            name="content_pl"
            hasFeedback
            help={errors.content_pl?.[0]}
            validateStatus={errors.content_pl ? 'error' : undefined}
          >
            <TextEditor />
          </Form.Item>

          <Form.Item
            label="Treść podstrony (EN)"
            name="content_en"
            hasFeedback
            help={errors.content_en?.[0]}
            validateStatus={errors.content_en ? 'error' : undefined}
          >
            <TextEditor />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Space>
              <Button className="mr-2" type="primary" htmlType="submit">
                Zapisz
              </Button>
              <Button className="mr-2" htmlType="button" onClick={() => form.resetFields()}>
                Reset
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </TitledCard>
    </Spin>
  );
};
export default DescriptionForm;
