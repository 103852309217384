import React, { useEffect, useState } from 'react';

import { Button, Form, Input, notification, Space } from 'antd';
import { useNavigate } from 'react-router-dom';

import developmentService, { MovieParams } from '../../../services/developmentService';
import ErrorsType from '../../../typings/ErrorsType';
import MovieType from '../../../typings/MovieType';
import FileInput from '../../utils/file-input';

interface Props {
  movieId?: number;
  setLoading: (value: boolean) => void;
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 14 },
};

const MovieForm: React.FC<Props> = ({ movieId, setLoading }) => {
  const [form] = Form.useForm<MovieParams>();
  const [movie, setMovie] = useState<MovieType>();
  const [errors, setErrors] = useState<Record<string, Array<string>>>({});

  const navigate = useNavigate();

  useEffect(() => {
    form.setFieldsValue({});
    if (movieId) {
      developmentService
        .getMovie(movieId)
        .then((response) => {
          form.setFieldsValue(response.movie);
          setMovie(response.movie);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setMovie(undefined);
    }
  }, [movieId, form, setLoading]);

  const catchErrors = (e: ErrorsType) => {
    if (e.statusCode === 422) {
      setErrors(e.errors || {});
    }
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  const onFinish = (values: MovieParams) => {
    setLoading(true);
    setErrors({});

    if (movieId) {
      developmentService
        .updateMovie(movieId, values)
        .then((response) => {
          notification.success({
            message: 'Zapisano film',
            description: (
              <>
                Dane filmu <strong>{response.movie.name_pl}</strong> zostały zaktualizowane.
              </>
            ),
          });
          navigate('../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    } else {
      developmentService
        .createMovie(values)
        .then((response) => {
          notification.success({
            message: 'Utworzono film',
            description: (
              <>
                Nowy film <strong>{response.movie.name_pl}</strong> został dodany.
              </>
            ),
          });
          navigate('../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onFinishFailed = () => {
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  return (
    <Form
      {...layout}
      form={form}
      name="newMovieForm"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Nazwa"
        name="name_pl"
        rules={[{ required: true, message: 'Nazwa jest wymagana' }]}
        hasFeedback
        help={errors.name_pl?.[0]}
        validateStatus={errors.name_pl ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Nazwa (EN)"
        name="name_en"
        hasFeedback
        help={errors.name_en?.[0]}
        validateStatus={errors.name_en ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Zdjęcie"
        name="image"
        hasFeedback
        help={errors.image?.[0]}
        validateStatus={errors.image ? 'error' : undefined}
      >
        <FileInput accept="image/jpeg,image/png" maxCount={1} defaultPreview={movie?.image_url} />
      </Form.Item>

      <Form.Item
        label="Film"
        name="video_pl"
        hasFeedback
        help={errors.video_pl?.[0]}
        validateStatus={errors.video_pl ? 'error' : undefined}
      >
        <FileInput accept="video/mp4" maxCount={1} defaultPreview={movie?.video_pl_url} />
      </Form.Item>

      <Form.Item
        label="Film (EN)"
        name="video_en"
        hasFeedback
        help={errors.video_en?.[0]}
        validateStatus={errors.video_en ? 'error' : undefined}
      >
        <FileInput accept="video/mp4" maxCount={1} defaultPreview={movie?.video_en_url} />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Space>
          <Button className="mr-2" type="primary" htmlType="submit">
            Zapisz
          </Button>
          <Button className="mr-2" htmlType="button" onClick={() => form.resetFields()}>
            Reset
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
export default MovieForm;
