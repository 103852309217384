import { serialize } from 'object-to-formdata';

import type PathType from '../../typings/map/PathType';

import { deleteRequest, getRequest, postRequest, putRequest } from '../../api/api-client';

interface PathsResponse {
  paths: Array<PathType>;
}

interface PathResponse {
  path: PathType;
}

export interface PathParams {
  name_pl: string;
  name_en: string;
  pdf_file_pl: File | null;
  pdf_file_en: File | null;
}

const getPaths = async (): Promise<PathsResponse> => {
  return getRequest('/map/paths');
};

const getPath = async (pathId: number): Promise<PathResponse> => {
  return getRequest(`/map/paths/${pathId}`);
};

const createPath = async (params: PathParams): Promise<PathResponse> => {
  return postRequest(`/map/paths`, serialize({ path: params }));
};

const updatePath = async (pathId: number, params: PathParams): Promise<PathResponse> => {
  return putRequest(`/map/paths/${pathId}`, serialize({ path: params }));
};
const deletePath = async (pathId: number): Promise<void> => {
  return deleteRequest(`/map/paths/${pathId}`);
};

const pathsService = {
  getPaths,
  getPath,
  createPath,
  updatePath,
  deletePath,
};

export default pathsService;
