import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Form, Input, Col, Row, Card, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import authService from '../../services/auth-service';

interface Props {
  setLoading: (value: boolean) => void;
}

interface LoginParams {
  email: string;
  password: string;
}

const LoginForm: React.FC<Props> = ({ setLoading }) => {
  const initialCredential = {
    email: '',
    password: '',
  };
  const [form] = Form.useForm<LoginParams>();

  const navigate = useNavigate();

  const handleSubmit = (data: LoginParams) => {
    setLoading(true);
    authService
      .loginEmployee(data)
      .then(() => {
        notification.success({ message: 'Zalogowałeś się na swoje konto' });
        navigate('/app');
      })
      .catch((e) => {
        if (e.statusCode === 422) {
          notification.error({ message: 'Użytkownik o podanych danych nie istnieje' });
        } else {
          notification.error({ message: 'Podczas logowania wystąpił błąd' });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Card>
      <div className="my-4">
        <div className="text-center">
          <img className="img-fluid mb-4" src="/img/logo.png" alt="" />
        </div>
        <Row justify="center">
          <Col xs={24} sm={24} md={20} lg={20}>
            <Form
              layout="vertical"
              name="login-form"
              form={form}
              initialValues={initialCredential}
              onFinish={handleSubmit}
            >
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                  },
                  {
                    type: 'email',
                  },
                ]}
              >
                <Input prefix={<MailOutlined className="text-primary" />} />
              </Form.Item>
              <Form.Item
                name="password"
                label="Hasło"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.Password prefix={<LockOutlined className="text-primary" />} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  Zaloguj się
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default LoginForm;
