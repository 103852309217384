import { useState, useEffect } from 'react';

import { Form, Input, Button, Space, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import type ErrorsType from '../../../typings/ErrorsType';

import employeesService, { type EmployeeParams } from '../../../services/employees-service';

interface Props {
  employeeId?: number;
  setLoading: (value: boolean) => void;
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 14 },
};

const EmployeeForm: React.FC<Props> = ({ employeeId, setLoading }) => {
  const [form] = Form.useForm<EmployeeParams>();
  const [errors, setErrors] = useState<Record<string, Array<string>>>({});
  const navigate = useNavigate();

  useEffect(() => {
    if (employeeId) {
      employeesService.getEmployee(employeeId).then((response) => {
        form.setFieldsValue(response.employee);
        setLoading(false);
      });
    }
  }, [form, employeeId, setLoading]);

  const catchErrors = (e: ErrorsType) => {
    if (e.statusCode === 422) {
      setErrors(e.errors || {});
    }
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  const onFinish = (values: EmployeeParams) => {
    setLoading(true);
    setErrors({});

    if (employeeId) {
      employeesService
        .updateEmployee(employeeId, values)
        .then(({ employee }) => {
          notification.success({
            message: 'Zapisano redaktora',
            description: (
              <>
                Dane redaktora{' '}
                <strong>
                  {employee.first_name} {employee.last_name}
                </strong>{' '}
                zostały zaktualizowane.
              </>
            ),
          });
          navigate('../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    } else {
      employeesService
        .createEmployee(values)
        .then(({ employee }) => {
          notification.success({
            message: 'Utworzono redaktora',
            description: (
              <>
                Nowy redaktor{' '}
                <strong>
                  {employee.first_name} {employee.last_name}
                </strong>{' '}
                został dodany.
              </>
            ),
          });
          navigate('../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onFinishFailed = () => {
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  return (
    <Form
      {...layout}
      form={form}
      name="newEmployeeForm"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="E-mail"
        name="email"
        rules={[{ required: true, message: 'Adres e-mail jest wymagany' }]}
        hasFeedback
        help={errors.email?.[0]}
        validateStatus={errors.email ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Imię"
        name="first_name"
        hasFeedback
        help={errors.first_name?.[0]}
        validateStatus={errors.first_name ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Nazwisko"
        name="last_name"
        hasFeedback
        help={errors.last_name?.[0]}
        validateStatus={errors.last_name ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Hasło"
        name="password"
        hasFeedback
        help={errors.password?.[0]}
        validateStatus={errors.password ? 'error' : undefined}
      >
        <Input.Password autoComplete="off" />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Space>
          <Button className="mr-2" type="primary" htmlType="submit">
            Zapisz
          </Button>
          <Button className="mr-2" htmlType="button" onClick={() => form.resetFields()}>
            Reset
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
export default EmployeeForm;
