import { useEffect, useState } from 'react';

import { Button, Table } from 'antd';
import { Link, useParams } from 'react-router-dom';

import spotsService from '../../../../services/professions/spotsService';
import SpotType from '../../../../typings/professions/SpotType';
import TitledCard from '../../../layout-components/titled-card';

const SpotsList: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [spots, setLocations] = useState<Array<SpotType>>([]);

  const { profession } = useParams();

  useEffect(() => {
    spotsService.getSpots(profession ?? '').then((response) => {
      setLocations(response.spots);
      setLoading(false);
    });
  }, [profession]);

  const columns = [
    {
      title: 'Nazwa',
      dataIndex: 'name_pl',
      render: (name_pl: string, record: SpotType) => (
        <Link to={`${record.id}/edit`}>{name_pl}</Link>
      ),
    },
    {
      title: 'Nazwa (EN)',
      dataIndex: 'name_en',
    },
    {
      title: 'Operacje',
      key: 'action',
      width: 200,
      align: 'center' as const,
      render: (_text: string, record: SpotType) => (
        <span>
          <Link to={`${record.id}/edit`}>
            <Button type="primary" size="small">
              Edycja
            </Button>
          </Link>
        </span>
      ),
    },
  ];

  return (
    <TitledCard title="Lista punktów">
      <Table
        rowKey="id"
        dataSource={spots}
        columns={columns}
        bordered
        loading={loading}
        pagination={false}
      />
    </TitledCard>
  );
};
export default SpotsList;
