import { Suspense } from 'react';

import { Routes, Route } from 'react-router-dom';

import EditRecipe from './edit-recipe';
import NewRecipe from './new-recipe';
import RecipesList from './recipes-list';

const RecipesRoutes: React.FC = () => {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <Routes>
        <Route path="/new" element={<NewRecipe />} />
        <Route path="/:recipeId/edit" element={<EditRecipe />} />
        <Route index element={<RecipesList />} />
      </Routes>
    </Suspense>
  );
};

export default RecipesRoutes;
