import { useState } from 'react';

import { Spin } from 'antd';
import { useParams } from 'react-router-dom';

import PictureForm from './picture-form';

import TitledCard from '../../../layout-components/titled-card';

const EditPicture: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const params = useParams();
  const pictureId = Number(params.pictureId);

  return (
    <Spin spinning={loading}>
      <TitledCard title="Legendy - Edycja zdjęcia" backUrl="..">
        <PictureForm pictureId={pictureId} setLoading={setLoading} />
      </TitledCard>
    </Spin>
  );
};
export default EditPicture;
