import { useState } from 'react';

import { Spin } from 'antd';

import RecipeForm from './recipe-form';

import TitledCard from '../../../layout-components/titled-card';

const NewRecipe: React.FC = () => {
  const [loading, setLoading] = useState(false);

  return (
    <Spin spinning={loading}>
      <TitledCard title="Nowy przepis" backUrl="..">
        <RecipeForm setLoading={setLoading} />
      </TitledCard>
    </Spin>
  );
};
export default NewRecipe;
