import { useEffect, useState } from 'react';

import { Button, Form, Input, notification, Space } from 'antd';
import { useNavigate } from 'react-router-dom';

import photographersService from '../../../../../services/photoBooth/photographers-service';
import picturesService, {
  type PictureParams,
} from '../../../../../services/photoBooth/pictures-service';
import ErrorsType from '../../../../../typings/ErrorsType';
import PhotographerType from '../../../../../typings/photoBooth/PhotographerType';
import FileInput from '../../../../utils/file-input';

interface Props {
  photographerId: number;
  pictureId?: number;
  setLoading: (value: boolean) => void;
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 14 },
};

const PictureForm: React.FC<Props> = ({ photographerId, pictureId, setLoading }) => {
  const [photographer, setPhotographer] = useState<PhotographerType>();
  const [defaultImage, setDefaultImage] = useState<string | null>();
  const [errors, setErrors] = useState<Record<string, Array<string>>>({});

  const [form] = Form.useForm<PictureParams>();
  const navigate = useNavigate();

  useEffect(() => {
    photographersService.getPhotographer(photographerId).then((response) => {
      setPhotographer(response.photographer);
      if (pictureId) {
        picturesService.getPicture(photographerId, pictureId).then((response2) => {
          form.setFieldsValue(response2.picture);
          setDefaultImage(response2.picture.thumb_image_url);
          setLoading(false);
        });
      } else {
        setDefaultImage(undefined);
      }
    });
  }, [photographerId, pictureId, form, setLoading]);

  const catchErrors = (e: ErrorsType) => {
    if (e.statusCode === 422) {
      setErrors(e.errors || {});
    } else {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw e;
    }
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  const onFinish = (values: PictureParams) => {
    setLoading(true);
    setErrors({});

    if (pictureId) {
      picturesService
        .updatePicture(photographerId, pictureId, values)
        .then(({ picture }) => {
          notification.success({
            message: 'Zapisano zdjęcie do biografii fotografa',
            description: (
              <>
                Dane zdjęcia <strong>{picture.id}</strong> w biografii{' '}
                <strong>{photographer?.title_pl}</strong> zostały zaktualizowane.
              </>
            ),
          });
          navigate('./../../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    } else {
      picturesService
        .createPicture(photographerId, values)
        .then(({ picture }) => {
          notification.success({
            message: 'Utworzono zdjęcie do biografii fotografa',
            description: (
              <>
                Nowe zdjęcie <strong>{picture.id}</strong> zostało dodane do biografii fotografa{' '}
                <strong>{photographer?.title_pl}</strong>.
              </>
            ),
          });
          navigate('./../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onFinishFailed = () => {
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  return (
    <Form
      {...layout}
      form={form}
      name="newPictureForm"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Zdjęcie"
        name="image"
        hasFeedback
        help={errors.image?.[0]}
        validateStatus={errors.image ? 'error' : undefined}
      >
        <FileInput accept="image/jpeg,image/png" maxCount={1} defaultPreview={defaultImage} />
      </Form.Item>

      <Form.Item
        label="Opis"
        name="description_pl"
        hasFeedback
        help={errors.description_pl?.[0]}
        validateStatus={errors.description_pl ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Opis (EN)"
        name="description_en"
        hasFeedback
        help={errors.description_en?.[0]}
        validateStatus={errors.description_en ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Space>
          <Button className="mr-2" type="primary" htmlType="submit">
            Zapisz
          </Button>
          <Button className="mr-2" htmlType="button" onClick={() => form.resetFields()}>
            Reset
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
export default PictureForm;
