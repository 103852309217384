import type LanguageType from '../../typings/language/LanguageType';
import type WordType from '../../typings/language/WordType';

import { deleteRequest, getRequest, postRequest, putRequest } from '../../api/api-client';

interface WordsResponse {
  words: Array<WordType>;
}

interface WordResponse {
  word: WordType;
}

interface LanguagesResponse {
  languages: Array<LanguageType>;
}

export interface WordParams {
  name_pl: string;
  name_en: string;
  text_form: string;
  description_pl: string;
  description_en: string;
  correct_language: string;
}

const getWords = async (): Promise<WordsResponse> => {
  return getRequest('/language/words');
};

const getWord = async (wordId: number): Promise<WordResponse> => {
  return getRequest(`/language/words/${wordId}`);
};

const createWord = async (params: WordParams): Promise<WordResponse> => {
  return postRequest(`/language/words`, { word: params });
};

const updateWord = async (wordId: number, params: WordParams): Promise<WordResponse> => {
  return putRequest(`/language/words/${wordId}`, { word: params });
};
const deleteWord = async (wordId: number): Promise<void> => {
  return deleteRequest(`/language/words/${wordId}`);
};

const getLanguages = async (): Promise<LanguagesResponse> => {
  return getRequest('/language/all');
};

const reorderWords = async (words: Array<{ id: number }>): Promise<WordsResponse> => {
  return postRequest('/language/words/reorder', { words });
};

const wordsService = {
  getWords,
  getWord,
  createWord,
  updateWord,
  deleteWord,
  getLanguages,
  reorderWords,
};

export default wordsService;
