import { serialize } from 'object-to-formdata';

import type StaticPage from '../../typings/StaticPage';

import { getRequest, putRequest } from '../../api/api-client';

interface StaticPageResponse {
  static_page: StaticPage;
}

export interface BiographyParams {
  title_pl: string;
  title_en: string;
  slug: string;
  sections: Array<
    | {
        id: number;
        title_pl: string;
        title_en: string;
        content_pl: string;
        content_en: string;
        image: File | null;
        image_description_pl: string;
        image_description_en: string;
      }
    | {
        id: number;
        _destroy?: boolean;
      }
  >;
}

const getBiography = async (slug: 'szymon-fabian' | 'jan-flatt'): Promise<StaticPageResponse> => {
  return getRequest(`/static-pages/pharmacy/${slug}`);
};

const updateBiography = async (
  slug: 'szymon-fabian' | 'jan-flatt',
  params: BiographyParams,
): Promise<StaticPageResponse> => {
  return putRequest(`/static-pages/pharmacy/${slug}`, serialize({ static_page: params }));
};

const biographyService = {
  getBiography,
  updateBiography,
};

export default biographyService;
