import { Suspense } from 'react';

import { Routes, Route } from 'react-router-dom';

import EditEmployee from './edit-employee';
import EmployeesList from './employees-list';
import NewEmployee from './new-employee';

const EmployeesRoutes: React.FC = () => {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <Routes>
        <Route path="/new" element={<NewEmployee />} />
        <Route path="/:employeeId/edit" element={<EditEmployee />} />
        <Route index element={<EmployeesList />} />
      </Routes>
    </Suspense>
  );
};

export default EmployeesRoutes;
