import { useState } from 'react';

import { Spin } from 'antd';

import EmployeeForm from './employee-form';

import TitledCard from '../../layout-components/titled-card';

const NewEmployee: React.FC = () => {
  const [loading, setLoading] = useState(false);

  return (
    <Spin spinning={loading}>
      <TitledCard title="Nowy redaktor" backUrl="..">
        <EmployeeForm setLoading={setLoading} />
      </TitledCard>
    </Spin>
  );
};
export default NewEmployee;
