import React, { useEffect, useState } from 'react';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, notification, Space, Spin } from 'antd';

import type StaticPage from '../../../typings/StaticPage';

import printingService, {
  StaticPageParams,
  staticPageToFormFields,
} from '../../../services/printing/printing-service';
import ErrorsType from '../../../typings/ErrorsType';
import TitledCard from '../../layout-components/titled-card';
import Flex from '../../layouts/flex';
import TextEditor from '../../layouts/text-editor';
import FileInput from '../../utils/file-input';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 14 },
};

const HistoryForm: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [staticPage, setStaticPage] = useState<StaticPage>();
  const [form] = Form.useForm<StaticPageParams>();
  const [errors, setErrors] = useState<Record<string, Array<string>>>({});
  const [removedSections, setRemovedSections] = useState<Array<number>>([]);

  useEffect(() => {
    printingService
      .getHistory()
      .then((response) => {
        setStaticPage(response.static_page);
        form.setFieldsValue(staticPageToFormFields(response.static_page));
      })
      .catch((e: ErrorsType) => {
        if (e.statusCode === 404) {
          form.setFieldsValue({ sections: [] });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [form]);

  const onFinish = (values: StaticPageParams) => {
    setLoading(true);
    setErrors({});

    const newSections = [
      ...values.sections,
      ...removedSections.map((sectionId) => ({
        id: sectionId,
        _destroy: true,
      })),
    ];

    printingService
      .updateHistory({ ...values, sections: newSections })
      .then((response) => {
        notification.success({
          message: 'Zapisano podstronę',
          description: (
            <>
              Dane podstrony <strong>Historia</strong> zostały zaktualizowane.
            </>
          ),
        });
        setStaticPage(response.static_page);
        form.setFieldsValue(staticPageToFormFields(response.static_page));
      })
      .catch((e: ErrorsType) => {
        if (e.statusCode === 422) {
          setErrors(e.errors || {});
        } else {
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw e;
        }
        notification.error({
          message: 'Dane nie mogły być zapisane',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = () => {
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  return (
    <Spin spinning={loading}>
      <TitledCard title='Edycja podstrony "Historia"'>
        <Form
          {...layout}
          form={form}
          name="historyForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Tytuł"
            name="title_pl"
            rules={[{ required: true, message: 'Tytuł jest wymagany' }]}
            hasFeedback
            help={errors.title_pl?.[0]}
            validateStatus={errors.title_pl ? 'error' : undefined}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Tytuł (EN)"
            name="title_en"
            rules={[{ required: true, message: 'Tytuł jest wymagany' }]}
            hasFeedback
            help={errors.title_en?.[0]}
            validateStatus={errors.title_en ? 'error' : undefined}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Identyfikator podstrony"
            name="slug"
            hasFeedback
            help={errors.slug?.[0]}
            validateStatus={errors.slug ? 'error' : undefined}
          >
            <Input readOnly />
          </Form.Item>

          <Form.List name="sections">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <React.Fragment key={field.key}>
                    <Divider />
                    <Form.Item name={[field.name, 'id']} noStyle>
                      <Input type="hidden" />
                    </Form.Item>
                    <Form.Item label="Tytuł sekcji (PL)" name={[field.name, 'title_pl']}>
                      <Input />
                    </Form.Item>
                    <Form.Item label="Tytuł sekcji (EN)" name={[field.name, 'title_en']}>
                      <Input />
                    </Form.Item>
                    <Form.Item label="Treść sekcji (PL)" name={[field.name, 'content_pl']}>
                      <TextEditor />
                    </Form.Item>
                    <Form.Item label="Treść sekcji (EN)" name={[field.name, 'content_en']}>
                      <TextEditor />
                    </Form.Item>
                    <Form.Item label="Zdjęcie" name={[field.name, 'image']}>
                      <FileInput
                        accept="image/jpeg,image/png"
                        maxCount={1}
                        defaultPreview={staticPage?.sections[field.key]?.thumb_image_url}
                      />
                    </Form.Item>
                    <Form.Item label="Podpis zdjęcia" name={[field.name, 'image_description_pl']}>
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Podpis zdjęcia (EN)"
                      name={[field.name, 'image_description_en']}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                      <Flex justifyContent="end">
                        <Button
                          type="primary"
                          danger
                          onClick={() => {
                            const sectionId = form.getFieldValue(['sections', field.name, 'id']);
                            if (sectionId) {
                              setRemovedSections((prevRemovedSections) => [
                                ...prevRemovedSections,
                                sectionId,
                              ]);
                            }
                            remove(field.name);
                          }}
                          icon={<DeleteOutlined />}
                        >
                          Usuń sekcję
                        </Button>
                      </Flex>
                    </Form.Item>
                  </React.Fragment>
                ))}

                <Form.Item {...tailLayout}>
                  <Button
                    type="primary"
                    onClick={() =>
                      add({
                        title_pl: '',
                        title_en: '',
                        content_pl: '',
                        content_en: '',
                      })
                    }
                    icon={<PlusOutlined />}
                    style={{ background: '#9b59b6', borderColor: '#8e44ad' }}
                  >
                    Dodaj sekcję
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.Item {...tailLayout}>
            <Space>
              <Button className="mr-2" type="primary" htmlType="submit">
                Zapisz
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </TitledCard>
    </Spin>
  );
};
export default HistoryForm;
