import { getRequest, postRequest } from '../api/api-client';
import WorkingHourType from '../typings/WorkingHourType';

export type WeekDayType =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday';

interface ScheduleResponse {
  schedule: Record<WeekDayType, WorkingHourType>;
}

export const dayNames: Record<WeekDayType, string> = {
  monday: 'Poniedziałek',
  tuesday: 'Wtorek',
  wednesday: 'Środa',
  thursday: 'Czwartek',
  friday: 'Piątek',
  saturday: 'Sobota',
  sunday: 'Niedziela',
};

export const hoursList = Array.from({ length: 48 }).map((_inx, index) => ({
  value: index * 30,
  label: `${index < 20 ? '0' : ''}${Math.floor(index / 2)}:${index % 2 ? '' : '0'}${
    (index % 2) * 30
  }`,
}));

export type ScheduleParams = {
  working_hours: Array<WorkingHourType>;
};

const getSchedule = async (): Promise<ScheduleResponse> => {
  return getRequest('/working-hours');
};

const updateSchedule = async (params: ScheduleParams): Promise<ScheduleResponse> => {
  return postRequest('/working-hours', params);
};

const scheduleService = {
  getSchedule,
  updateSchedule,
};

export default scheduleService;
