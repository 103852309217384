import './App.css';

import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import AppLayout from './components/layouts/app-layout';
import AuthLayout from './components/layouts/auth-layout';
import store from './redux/store';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate replace to="/app" />} />
          <Route path="/app" element={<Navigate replace to="/app/workstations" />} />
          <Route path="/auth" element={<AuthLayout />} />
          <Route path="/app/*" element={<AppLayout />} />
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;
