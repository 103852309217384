import { useState } from 'react';

import { Spin } from 'antd';
import { useParams } from 'react-router-dom';

import PathForm from './path-form';

import TitledCard from '../../../layout-components/titled-card';

const EditPath: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const params = useParams<{ pathId: string }>();
  const pathId = Number(params.pathId);

  return (
    <Spin spinning={loading}>
      <TitledCard title="Edycja ścieżki zwiedzania" backUrl="..">
        <PathForm pathId={pathId} setLoading={setLoading} />
      </TitledCard>
    </Spin>
  );
};
export default EditPath;
