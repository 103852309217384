import { Suspense } from 'react';

import { Routes, Route } from 'react-router-dom';

import EditSpot from './edit-spot';
import SpotsList from './spots-list';

// import EditLocation from './edit-location';
// import LocationsList from './locations-list';

const SpotsRoutes: React.FC = () => {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <Routes>
        <Route path="/:spotId/edit" element={<EditSpot />} />
        <Route index element={<SpotsList />} />
      </Routes>
    </Suspense>
  );
};

export default SpotsRoutes;
