import { Suspense } from 'react';

import { Routes, Route } from 'react-router-dom';

import EditMovie from './edit-movie';
import MoviesList from './movies-list';
import NewMovie from './new-movie';

const MoviesRoutes: React.FC = () => {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <Routes>
        <Route path="/new" element={<NewMovie />} />
        <Route path="/:movieId/edit" element={<EditMovie />} />
        <Route index element={<MoviesList />} />
      </Routes>
    </Suspense>
  );
};

export default MoviesRoutes;
