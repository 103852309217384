import React, { useEffect, useState } from 'react';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, notification, Space } from 'antd';
import { useNavigate } from 'react-router-dom';

import type StaticPage from '../../../../typings/StaticPage';

import locationsService, { LocationParams } from '../../../../services/industry/locationsService';
import { staticPageToFormFields } from '../../../../services/printing/printing-service';
import ErrorsType from '../../../../typings/ErrorsType';
import Flex from '../../../layouts/flex';
import TextEditor from '../../../layouts/text-editor';
import FileInput from '../../../utils/file-input';

interface Props {
  locationSlug?: string;
  setLoading: (value: boolean) => void;
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 14 },
};

const LocationForm: React.FC<Props> = ({ locationSlug, setLoading }) => {
  const [staticPage, setStaticPage] = useState<StaticPage>();
  const [errors, setErrors] = useState<Record<string, Array<string>>>({});
  const [removedSections, setRemovedSections] = useState<Array<number>>([]);

  const [form] = Form.useForm<LocationParams>();
  const navigate = useNavigate();

  useEffect(() => {
    form.setFieldsValue({ sections: [] });
    if (locationSlug) {
      locationsService
        .getLocation(locationSlug)
        .then((response) => {
          setStaticPage(response.static_page);
          form.setFieldsValue(staticPageToFormFields(response.static_page));
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      form.setFieldsValue({ sections: [] });
    }
  }, [locationSlug, form, setLoading]);

  const catchErrors = (e: ErrorsType) => {
    if (e.statusCode === 422) {
      setErrors(e.errors || {});
    }
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  const onFinish = (values: LocationParams) => {
    setLoading(true);
    setErrors({});

    const newSections = [
      ...values.sections,
      ...removedSections.map((sectionId) => ({
        id: sectionId,
        _destroy: true,
      })),
    ];

    if (locationSlug) {
      locationsService
        .updateLocation(locationSlug, { ...values, sections: newSections })
        .then(({ static_page }) => {
          notification.success({
            message: 'Zapisano miejsce',
            description: (
              <>
                Dane miejsca <strong>{static_page.title_pl}</strong> zostały zaktualizowane.
              </>
            ),
          });
          navigate('../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    } else {
      locationsService
        .createLocation(values)
        .then(({ static_page }) => {
          notification.success({
            message: 'Utworzono miejsce',
            description: (
              <>
                Nowe miejsce <strong>{static_page.title_pl}</strong> zostało dodane.
              </>
            ),
          });
          navigate('../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onFinishFailed = () => {
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  return (
    <Form
      {...layout}
      form={form}
      name="newLocationForm"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Tytuł"
        name="title_pl"
        rules={[{ required: true, message: 'Tytuł jest wymagany' }]}
        hasFeedback
        help={errors.title_pl?.[0]}
        validateStatus={errors.title_pl ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Tytuł (EN)"
        name="title_en"
        rules={[{ required: true, message: 'Tytuł jest wymagany' }]}
        hasFeedback
        help={errors.title_en?.[0]}
        validateStatus={errors.title_en ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Identyfikator podstrony"
        name="slug"
        rules={[{ required: true, message: 'Identyfikator jest wymagany' }]}
        hasFeedback
        help={errors.slug?.[0]}
        validateStatus={errors.slug ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Zdjęcie"
        name="cover_image"
        hasFeedback
        help={errors.cover_image?.[0]}
        validateStatus={errors.cover_image ? 'error' : undefined}
      >
        <FileInput
          accept="image/jpeg,image/png"
          maxCount={1}
          defaultPreview={staticPage?.cover_image_url}
        />
      </Form.Item>

      <Form.List name="sections">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <React.Fragment key={field.key}>
                <Divider />
                <Form.Item name={[field.name, 'id']} noStyle>
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item label="Tytuł sekcji (PL)" name={[field.name, 'title_pl']}>
                  <Input />
                </Form.Item>
                <Form.Item label="Tytuł sekcji (EN)" name={[field.name, 'title_en']}>
                  <Input />
                </Form.Item>
                <Form.Item label="Treść sekcji (PL)" name={[field.name, 'content_pl']}>
                  <TextEditor />
                </Form.Item>
                <Form.Item label="Treść sekcji (EN)" name={[field.name, 'content_en']}>
                  <TextEditor />
                </Form.Item>
                <Form.Item label="Zdjęcie" name={[field.name, 'image']}>
                  <FileInput
                    accept="image/jpeg,image/png"
                    maxCount={1}
                    defaultPreview={staticPage?.sections[field.key]?.thumb_image_url}
                  />
                </Form.Item>
                <Form.Item label="Podpis zdjęcia" name={[field.name, 'image_description_pl']}>
                  <Input />
                </Form.Item>
                <Form.Item label="Podpis zdjęcia (EN)" name={[field.name, 'image_description_en']}>
                  <Input />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Flex justifyContent="end">
                    <Button
                      type="primary"
                      danger
                      onClick={() => {
                        const sectionId = form.getFieldValue(['sections', field.name, 'id']);
                        if (sectionId) {
                          setRemovedSections((prevRemovedSections) => [
                            ...prevRemovedSections,
                            sectionId,
                          ]);
                        }
                        remove(field.name);
                      }}
                      icon={<DeleteOutlined />}
                    >
                      Usuń sekcję
                    </Button>
                  </Flex>
                </Form.Item>
              </React.Fragment>
            ))}

            <Form.Item {...tailLayout}>
              <Button
                type="primary"
                onClick={() =>
                  add({
                    title_pl: '',
                    title_en: '',
                    content_pl: '',
                    content_en: '',
                  })
                }
                icon={<PlusOutlined />}
                style={{ background: '#9b59b6', borderColor: '#8e44ad' }}
              >
                Dodaj sekcję
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.Item {...tailLayout}>
        <Space>
          <Button className="mr-2" type="primary" htmlType="submit">
            Zapisz
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
export default LocationForm;
