import { useState } from 'react';

import { Spin } from 'antd';
import { useParams } from 'react-router-dom';

import CuisineForm from './cuisine-form';

import TitledCard from '../../../layout-components/titled-card';

const EditCuisine: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const params = useParams<{ cuisineId: string }>();
  const cuisineId = Number(params.cuisineId);

  return (
    <Spin spinning={loading}>
      <TitledCard title="Edycja kuchnii" backUrl="..">
        <CuisineForm cuisineId={cuisineId} setLoading={setLoading} />
      </TitledCard>
    </Spin>
  );
};
export default EditCuisine;
