import { Suspense } from 'react';

import { Routes, Route } from 'react-router-dom';

import EditPath from './edit-path';
import NewPath from './new-path';
import PathsList from './paths-list';

import EditPathPoint from '../path-points/edit-path-point';
import NewPathPoint from '../path-points/new-path-point';
import PathPointsList from '../path-points/path-points-list';

const PathsRoutes: React.FC = () => {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <Routes>
        <Route path="/new" element={<NewPath />} />
        <Route path="/:pathId/edit" element={<EditPath />} />
        <Route index element={<PathsList />} />
        <Route path="/:pathId/points">
          <Route path="/:pathId/points/new" element={<NewPathPoint />} />
          <Route path="/:pathId/points/:pathPointId/edit" element={<EditPathPoint />} />
          <Route index element={<PathPointsList />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default PathsRoutes;
