import { Suspense } from 'react';

import { Routes, Route } from 'react-router-dom';

import EditLocation from './edit-location';
import LocationsList from './locations-list';
import NewLocation from './new-location';

const LocationsRoutes: React.FC = () => {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <Routes>
        <Route path="/new" element={<NewLocation />} />
        <Route path="/:locationSlug/edit" element={<EditLocation />} />
        <Route index element={<LocationsList />} />
      </Routes>
    </Suspense>
  );
};

export default LocationsRoutes;
