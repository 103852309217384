import { useEffect, useState } from 'react';

import { Col, notification, Row, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';

import authService from '../../services/auth-service';
import LoginForm from '../layout-components/login-form';
import PageTitle from '../layout-components/page-title';

const backgroundStyle = {
  backgroundImage: 'url(/images/login-bgr.jpg)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

const AuthLayout: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    authService
      .getProfile()
      .then(() => {
        notification.error({ message: 'Jesteś już zalogowany' });
        navigate('/app');
      })
      .catch(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="auth-container">
      <PageTitle title="Logowanie" />
      <div className="h-100" style={backgroundStyle}>
        <div className="container d-flex flex-column justify-content-center h-100">
          {loading ? (
            <Spin />
          ) : (
            <Row justify="center">
              <Col xs={20} sm={20} md={20} lg={7}>
                <LoginForm setLoading={setLoading} />
              </Col>
            </Row>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
