import type SpotType from '../../typings/professions/SpotType';

import { getRequest, putRequest } from '../../api/api-client';

interface SpotsResponse {
  spots: Array<SpotType>;
}

interface SpotResponse {
  spot: SpotType;
}

export interface SpotParams {
  name_pl: string;
  name_en: string;
  description_pl: string;
  description_en: string;
}

const getSpots = async (profession: string): Promise<SpotsResponse> => {
  return getRequest(`/professions/${profession}/spots/`);
};

const getSpot = async (profession: string, spotId: number): Promise<SpotResponse> => {
  return getRequest(`/professions/${profession}/spots/${spotId}`);
};

const updateSpot = async (
  profession: string,
  spotId: number,
  params: SpotParams,
): Promise<SpotResponse> => {
  return putRequest(`/professions/${profession}/spots/${spotId}`, { spot: params });
};

const spotService = {
  getSpots,
  getSpot,
  updateSpot,
};

export default spotService;
