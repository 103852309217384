import React, { useEffect, useState } from 'react';

import { Button, Form, Input, notification, Space } from 'antd';
import { useNavigate } from 'react-router-dom';

import type ErrorsType from '../../../../typings/ErrorsType';

import photographersService, {
  PhotographerParams,
} from '../../../../services/photoBooth/photographers-service';
import TextEditor from '../../../layouts/text-editor';
import FileInput from '../../../utils/file-input';

interface Props {
  photographerId?: number;
  setLoading: (value: boolean) => void;
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 14 },
};

const PhotographerForm: React.FC<Props> = ({ photographerId, setLoading }) => {
  const [defaultImage, setDefaultImage] = useState<string | null>();
  const [errors, setErrors] = useState<Record<string, Array<string>>>({});

  const [form] = Form.useForm<PhotographerParams>();

  const navigate = useNavigate();

  useEffect(() => {
    form.setFieldsValue({});
    if (photographerId) {
      photographersService
        .getPhotographer(photographerId)
        .then((response) => {
          form.setFieldsValue(response.photographer);
          setDefaultImage(response.photographer.thumb_cover_image_url);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setDefaultImage(undefined);
    }
  }, [photographerId, form, setLoading]);

  const catchErrors = (e: ErrorsType) => {
    if (e.statusCode === 422) {
      setErrors(e.errors || {});
    }
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  const onFinish = (values: PhotographerParams) => {
    setLoading(true);
    setErrors({});

    if (photographerId) {
      photographersService
        .updatePhotographer(photographerId, values)
        .then(({ photographer }) => {
          notification.success({
            message: 'Zapisano biografię fotografa',
            description: (
              <>
                Dane biografii fotografa <strong>{photographer.title_pl}</strong> zostały
                zaktualizowane.
              </>
            ),
          });
          navigate('../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    } else {
      photographersService
        .createPhotographer(values)
        .then(({ photographer }) => {
          notification.success({
            message: 'Utworzono biografię fotografa',
            description: (
              <>
                Nowa biografia fotografa <strong>{photographer.title_pl}</strong> została dodana.
              </>
            ),
          });
          navigate('../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onFinishFailed = () => {
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  return (
    <Form
      {...layout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Nazwa"
        name="title_pl"
        rules={[{ required: true, message: 'Nazwa jest wymagana' }]}
        hasFeedback
        help={errors.title_pl?.[0]}
        validateStatus={errors.title_pl ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Nazwa (EN)"
        name="title_en"
        rules={[{ required: true, message: 'Nazwa jest wymagana' }]}
        hasFeedback
        help={errors.title_en?.[0]}
        validateStatus={errors.title_en ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Zdjęcie"
        name="cover_image"
        hasFeedback
        help={errors.cover_image?.[0]}
        validateStatus={errors.cover_image ? 'error' : undefined}
      >
        <FileInput accept="image/jpeg,image/png" maxCount={1} defaultPreview={defaultImage} />
      </Form.Item>

      <Form.Item
        label="Opis"
        name="content_pl"
        hasFeedback
        help={errors.content_pl?.[0]}
        validateStatus={errors.content_pl ? 'error' : undefined}
      >
        <TextEditor />
      </Form.Item>

      <Form.Item
        label="Opis (EN)"
        name="content_en"
        hasFeedback
        help={errors.content_en?.[0]}
        validateStatus={errors.content_en ? 'error' : undefined}
      >
        <TextEditor />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Space>
          <Button className="mr-2" type="primary" htmlType="submit">
            Zapisz
          </Button>
          <Button className="mr-2" htmlType="button" onClick={() => form.resetFields()}>
            Reset
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
export default PhotographerForm;
