import { useCallback, useEffect, useState } from 'react';

import { MenuOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Table, notification, Button, Divider, Popconfirm } from 'antd';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Link } from 'react-router-dom';

import type PhotographerType from '../../../../typings/photoBooth/PhotographerType';

import photographersService from '../../../../services/photoBooth/photographers-service';
import TitledCard from '../../../layout-components/titled-card';
import Flex from '../../../layouts/flex';
import DraggableBodyRow from '../../../utils/draggable-body-row';

const PhotographersList: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [photographers, setphotographers] = useState<Array<PhotographerType>>([]);

  const reloadList = () => {
    photographersService.getPhotographers().then((response) => {
      setphotographers(response.photographers);
      setLoading(false);
    });
  };

  useEffect(reloadList, []);

  const removeItem = useCallback((record: PhotographerType) => {
    setLoading(true);
    photographersService
      .deletePhotographer(record.id)
      .then(() => {
        notification.success({
          message: 'Usunięto biografię fotografa',
          description: (
            <>
              Biografia fotografa<strong>{record.title_pl}</strong> została usunięta.
            </>
          ),
        });

        reloadList();
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      width: 50,
      className: 'drag-visible',
      render: () => <MenuOutlined />,
      align: 'center' as const,
    },
    {
      title: 'Nazwa',
      dataIndex: 'title_pl',
      render: (title_pl: string, record: PhotographerType) => (
        <Link to={`${record.id}/edit`}>{title_pl}</Link>
      ),
    },
    {
      title: 'Zdjęcie',
      dataIndex: 'thumb_cover_image_url',
      render: (imageUrl: string | null, record: PhotographerType) =>
        imageUrl ? <img src={imageUrl} alt={record.title_pl} /> : null,
      align: 'center' as const,
      width: 200,
    },
    {
      title: 'Galeria',
      key: 'gallery',
      width: 200,
      align: 'center' as const,
      render: (_text: string, record: PhotographerType) => (
        <span>
          <Link to={`${record.id}/pictures`}>
            <Button type="primary" size="small">
              Zdjęcia
            </Button>
          </Link>
        </span>
      ),
    },
    {
      title: 'Operacje',
      key: 'action',
      width: 200,
      align: 'center' as const,
      render: (_text: string, record: PhotographerType) => (
        <span>
          <Link to={`${record.id}/edit`}>
            <Button type="primary" size="small">
              Edycja
            </Button>
          </Link>

          <Divider type="vertical" />

          <Popconfirm
            title={
              <>
                Czy na pewno usunąć biografię fotografa <strong>{record.title_pl}</strong>?
              </>
            }
            onConfirm={() => removeItem(record)}
            okText="Tak"
            cancelText="Nie"
          >
            <Button type="primary" size="small" danger>
              Usuń
            </Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = photographers[dragIndex];
      const newPhotographers = update(photographers, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });

      setLoading(true);
      photographersService
        .reorderPhotographers(newPhotographers.map((photographer) => ({ id: photographer.id })))
        .then((response) => {
          setphotographers(response.photographers);
          setLoading(false);
        });
    },
    [photographers],
  );

  return (
    <TitledCard title="Lista fotografów">
      <Flex className="mb-3" alignItems="center" justifyContent="end" mobileFlex={false}>
        <div>
          <Link to="new">
            <Button type="primary" icon={<PlusCircleOutlined />} block>
              Dodaj fotografa
            </Button>
          </Link>
        </div>
      </Flex>
      <DndProvider backend={HTML5Backend}>
        <Table
          rowKey="id"
          dataSource={photographers}
          columns={columns}
          bordered
          loading={loading}
          pagination={false}
          components={components}
          // @ts-ignore
          onRow={(_record, index) => ({
            index,
            moveRow,
          })}
        />
      </DndProvider>
    </TitledCard>
  );
};
export default PhotographersList;
