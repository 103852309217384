import type StaticPage from '../../typings/StaticPage';

import { getRequest, putRequest } from '../../api/api-client';

interface StaticPageResponse {
  static_page: StaticPage;
}

export interface DescriptionParams {
  id: number;
  title_pl: string;
  title_en: string;
  content_pl: string;
  content_en: string;
}

const getDescription = async (): Promise<StaticPageResponse> => {
  return getRequest('/static-pages/photobooth/atelier-fotograficzne');
};

const updateDescription = async (params: DescriptionParams): Promise<StaticPageResponse> => {
  const data = {
    title_pl: 'Atelier fotograficzne',
    slug: 'atelier-fotograficzne',
    sections: [params],
  };
  return putRequest('/static-pages/photobooth/atelier-fotograficzne', { static_page: data });
};

const descriptionService = {
  getDescription,
  updateDescription,
};

export default descriptionService;
