import { serialize } from 'object-to-formdata';

import type StaticPage from '../../typings/StaticPage';

import { getRequest, putRequest } from '../../api/api-client';

interface AirportResponse {
  static_page: StaticPage;
}

export interface AirportParams {
  title_pl: string;
  title_en: string;
  slug: string;
  sections: Array<{
    id?: number;
    content_pl: string;
    content_en: string;
  }>;
}

const getAirport = async (): Promise<AirportResponse> => {
  return getRequest('/static-pages/map/airport');
};

const updateAirport = async (params: AirportParams): Promise<AirportResponse> => {
  return putRequest('/static-pages/map/airport', serialize({ static_page: params }));
};

const airportService = {
  getAirport,
  updateAirport,
};

export default airportService;

export const airportPageToFormFields = (staticPage: StaticPage) => ({
  title_pl: staticPage.title_pl,
  title_en: staticPage.title_en,
  slug: staticPage.slug,
  sections: staticPage.sections.map((section) => ({
    id: section.id,
    title_pl: '',
    title_en: '',
    content_pl: section.content_pl,
    content_en: section.content_en,
  })),
});
