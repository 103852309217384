import { EditOutlined, LogoutOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Avatar } from 'antd';
import { useNavigate } from 'react-router-dom';

import type EmployeeType from '../../typings/EmployeeType';

import authService from '../../services/auth-service';
import Icon from '../layouts/icon';

interface Props {
  employee: EmployeeType;
}

const menuItems = [
  {
    title: 'Edycja profilu',
    icon: EditOutlined,
    path: '/my-profile',
  },
];

const NavProfile: React.FC<Props> = ({ employee }) => {
  const navigate = useNavigate();

  const handleSignOut = () => {
    authService.logoutEmployee();
    navigate('/auth');
  };

  const profileImg = '/images/avatar.png';

  const dropdownMenuItems = menuItems.map((el, i) => ({
    label: (
      <a href={el.path}>
        <Icon className="mr-3" type={el.icon} />
        <span className="font-weight-normal">{el.title}</span>
      </a>
    ),
    key: i,
  }));

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">
              {employee.first_name} {employee.last_name}
            </h4>
            <span className="text-muted">Administrator</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu
          prefixCls="ant-menu"
          items={[
            ...dropdownMenuItems,
            {
              label: (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a href="#" onClick={handleSignOut}>
                  <LogoutOutlined className="mr-3" />
                  <span className="font-weight-normal">Wyloguj się</span>
                </a>
              ),
              key: dropdownMenuItems.length + 1,
            },
          ]}
        />
      </div>
    </div>
  );

  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={['click']}>
      <Menu
        className="d-flex align-item-center"
        mode="horizontal"
        items={[
          {
            key: 'profile',
            label: null,
            icon: <Avatar src={profileImg} />,
          },
        ]}
      />
    </Dropdown>
  );
};

export default NavProfile;
