import { useEffect, useState } from 'react';

import { Button, Form, Input, notification, Space } from 'antd';
import { useNavigate } from 'react-router-dom';

import dialoguesService, { DialogueParams } from '../../../../services/language/dialogues-service';
import ErrorsType from '../../../../typings/ErrorsType';
import FileInput from '../../../utils/file-input';

interface Props {
  dialogueId?: number;
  setLoading: (value: boolean) => void;
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 14 },
};

const DialogueForm: React.FC<Props> = ({ dialogueId, setLoading }) => {
  const [defaultAudio, setDefaultAudio] = useState<string | null>();
  const [errors, setErrors] = useState<Record<string, Array<string>>>({});

  const [form] = Form.useForm<DialogueParams>();
  const navigate = useNavigate();

  useEffect(() => {
    if (dialogueId) {
      dialoguesService.getDialogue(dialogueId).then((response) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { audio_url, ...formFields } = response.dialogue;
        form.setFieldsValue(formFields);
        setDefaultAudio(audio_url);
        setLoading(false);
      });
    } else {
      setDefaultAudio(undefined);
    }
  }, [dialogueId, form, setLoading]);

  const catchErrors = (e: ErrorsType) => {
    if (e.statusCode === 422) {
      setErrors(e.errors || {});
    } else {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw e;
    }
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  const onFinish = (values: DialogueParams) => {
    setLoading(true);
    setErrors({});

    if (dialogueId) {
      dialoguesService
        .updateDialogue(dialogueId, values)
        .then(({ dialogue }) => {
          notification.success({
            message: 'Zapisano dialog',
            description: (
              <>
                Dane dialogu <strong>{dialogue.title_pl}</strong> zostały zaktualizowane.
              </>
            ),
          });
          navigate('../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    } else {
      dialoguesService
        .createDialogue(values)
        .then(({ dialogue }) => {
          notification.success({
            message: 'Utworzono dialog',
            description: (
              <>
                Nowy dialog <strong>{dialogue.title_pl}</strong> został dodany.
              </>
            ),
          });
          navigate('../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onFinishFailed = () => {
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  return (
    <Form
      {...layout}
      form={form}
      name="newDialogueForm"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Tytuł"
        name="title_pl"
        rules={[{ required: true, message: 'Tytuł jest wymagana' }]}
        hasFeedback
        help={errors.title_pl?.[0]}
        validateStatus={errors.title_pl ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Tytuł (EN)"
        name="title_en"
        rules={[{ required: true, message: 'Tytuł jest wymagana' }]}
        hasFeedback
        help={errors.title_en?.[0]}
        validateStatus={errors.title_en ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Opis"
        name="description_pl"
        hasFeedback
        help={errors.description_pl?.[0]}
        validateStatus={errors.description_pl ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Opis (EN)"
        name="description_en"
        hasFeedback
        help={errors.description_en?.[0]}
        validateStatus={errors.description_en ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Plik dialogu" name="audio">
        <FileInput accept="audio/mpeg" maxCount={1} defaultPreview={defaultAudio} />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Space>
          <Button className="mr-2" type="primary" htmlType="submit">
            Zapisz
          </Button>
          <Button className="mr-2" htmlType="button" onClick={() => form.resetFields()}>
            Reset
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
export default DialogueForm;
