import { Suspense } from 'react';

import { Routes, Route } from 'react-router-dom';

import EditPicture from './edit-picture';
import NewPicture from './new-picture';
import PicturesList from './pictures-list';

const PicturesRoutes: React.FC = () => {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <Routes>
        <Route path="/new" element={<NewPicture />} />
        <Route path="/:pictureId/edit" element={<EditPicture />} />
        <Route index element={<PicturesList />} />
      </Routes>
    </Suspense>
  );
};

export default PicturesRoutes;
