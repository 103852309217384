import { useState } from 'react';

import { Spin } from 'antd';

import DialogueForm from './dialogue-form';

import TitledCard from '../../../layout-components/titled-card';

const NewDialogue: React.FC = () => {
  const [loading, setLoading] = useState(false);

  return (
    <Spin spinning={loading}>
      <TitledCard title="Nowy dialog" backUrl="..">
        <DialogueForm setLoading={setLoading} />
      </TitledCard>
    </Spin>
  );
};
export default NewDialogue;
