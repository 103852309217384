import { useCallback, useEffect, useRef, useState } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import {
  notification,
  Button,
  Divider,
  Popconfirm,
  Col,
  Row,
  Card,
  Upload,
  UploadFile,
  Spin,
} from 'antd';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Link } from 'react-router-dom';

import picturesService from '../../../services/pictures-service';
import PictureType from '../../../typings/PictureType';
import TitledCard from '../../layout-components/titled-card';

import './pictures-list.scss';

const type = 'DragableUploadList';

interface DragableUploadListItemProps {
  picture: PictureType;
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  removeItem: (item: PictureType) => void;
}

const DragableUploadListItem = ({
  moveRow,
  picture,
  index,
  removeItem,
}: DragableUploadListItemProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item: any) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <Col span={4} key={picture.id} ref={ref} className={isOver ? dropClassName : ''}>
      <Card hoverable>
        <div className="card-cover">
          <img src={picture.thumb_image_url} alt="" />
        </div>
        <div className="card-actions">
          <span>
            <Link to={`${picture.id}/edit`}>
              <Button type="primary" size="small">
                Edycja
              </Button>
            </Link>

            <Divider type="vertical" />

            <Popconfirm
              title="Czy na pewno usunąć zdjęcie?"
              onConfirm={() => removeItem(picture)}
              okText="Tak"
              cancelText="Nie"
            >
              <Button type="primary" size="small" danger>
                Usuń
              </Button>
            </Popconfirm>
          </span>
        </div>
      </Card>
    </Col>
  );
};

const PicturesList: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [pictures, setPictures] = useState<Array<PictureType>>([]);

  const reloadList = () => {
    picturesService.getPictures().then((response) => {
      setPictures(response.pictures);
      setLoading(false);
    });
  };

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = pictures[dragIndex];
      const newPictures = [...pictures];
      newPictures.splice(dragIndex, 1);
      newPictures.splice(hoverIndex, 0, dragRow);
      setPictures(newPictures);

      setLoading(true);

      picturesService.reorderPictures(newPictures).then((response) => {
        setPictures(response.pictures);
        setLoading(false);
      });
    },
    [pictures],
  );

  useEffect(reloadList, []);

  const removeItem = useCallback((record: PictureType) => {
    setLoading(true);
    picturesService
      .deletePicture(record.id)
      .then(() => {
        notification.success({
          message: 'Usunięto zdjęcie',
          description: `Zdjęcie ${record.id} zostało usunięte`,
        });
        reloadList();
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const handleUpload = async ({ file, fileList }: { file: UploadFile; fileList: UploadFile[] }) => {
    if (file.uid === fileList[0].uid) {
      fileList.forEach(async (newFile) => {
        if (newFile.originFileObj) {
          await picturesService.createPicture({ image: newFile.originFileObj });
          reloadList();
        }
      });
    }
  };

  return (
    <TitledCard title="Lista zdjęć">
      <Spin spinning={loading}>
        <Row id="picturesList" gutter={12}>
          <DndProvider backend={HTML5Backend}>
            {pictures.map((picture, index) => (
              <DragableUploadListItem
                key={picture.id}
                picture={picture}
                index={index}
                moveRow={moveRow}
                removeItem={removeItem}
              />
            ))}
          </DndProvider>
          <Col span={4}>
            <Card className="upload-card" hoverable>
              <Upload
                beforeUpload={() => false}
                fileList={[]}
                accept="image/jpeg,image/png"
                showUploadList={false}
                multiple
                onChange={handleUpload}
              >
                <Button type="primary" icon={<UploadOutlined />}>
                  Dodaj zdjęcia
                </Button>
              </Upload>
            </Card>
          </Col>
        </Row>
      </Spin>
    </TitledCard>
  );
};
export default PicturesList;
