import { Suspense } from 'react';

import { Routes, Route } from 'react-router-dom';

import EditPhotographer from './edit-photographer';
import NewPhotographer from './new-photographer';
import PhotographersList from './photographers-list';
import EditPicture from './pictures/edit-picture';
import NewPicture from './pictures/new-picture';
import PicturesList from './pictures/pictures-list';

const PhotographersRoutes: React.FC = () => {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <Routes>
        <Route path="/new" element={<NewPhotographer />} />
        <Route path="/:photographerId/edit" element={<EditPhotographer />} />
        <Route path="/:photographerId/pictures">
          <Route path="/:photographerId/pictures/new" element={<NewPicture />} />
          <Route path="/:photographerId/pictures/:pictureId/edit" element={<EditPicture />} />
          <Route index element={<PicturesList />} />
        </Route>
        <Route index element={<PhotographersList />} />
      </Routes>
    </Suspense>
  );
};

export default PhotographersRoutes;
