import { useCallback, useEffect, useState } from 'react';

import { MenuOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Divider, notification, Popconfirm, Table } from 'antd';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Link } from 'react-router-dom';

import type WordType from '../../../../typings/language/WordType';

import wordsService from '../../../../services/language/words-service';
import TitledCard from '../../../layout-components/titled-card';
import Flex from '../../../layouts/flex';
import DraggableBodyRow from '../../../utils/draggable-body-row';

const WordsList: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [words, setWords] = useState<Array<WordType>>([]);

  const reloadList = () => {
    wordsService.getWords().then((response) => {
      setWords(response.words);
      setLoading(false);
    });
  };

  useEffect(reloadList, []);

  const removeItem = useCallback((record: WordType) => {
    setLoading(true);
    wordsService
      .deleteWord(record.id)
      .then(() => {
        notification.success({
          message: 'Usunięto słowo ze słownika',
          description: (
            <>
              Słowo <strong>{record.name_pl}</strong> zostało usunięte ze słownika.
            </>
          ),
        });
        reloadList();
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      width: 50,
      className: 'drag-visible',
      render: () => <MenuOutlined />,
      align: 'center' as const,
    },
    {
      title: 'Nazwa',
      dataIndex: 'name_pl',
      render: (name_pl: string, record: WordType) => (
        <Link to={`${record.id}/edit`}>{name_pl}</Link>
      ),
    },
    {
      title: 'Nazwa (EN)',
      dataIndex: 'name_en',
    },
    {
      title: 'Język źródłowy',
      dataIndex: 'correct_language',
      align: 'center' as const,
      width: 250,
    },
    {
      title: 'Operacje',
      key: 'action',
      width: 200,
      align: 'center' as const,
      render: (_text: string, record: WordType) => (
        <span>
          <Link to={`${record.id}/edit`}>
            <Button type="primary" size="small">
              Edycja
            </Button>
          </Link>

          <Divider type="vertical" />

          <Popconfirm
            title={
              <>
                Czy na pewno usunąć słowo <strong>{record.name_pl}</strong> ze słownika?
              </>
            }
            onConfirm={() => removeItem(record)}
            okText="Tak"
            cancelText="Nie"
          >
            <Button type="primary" size="small" danger>
              Usuń
            </Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = words[dragIndex];
      const newWords = update(words, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });

      setLoading(true);
      wordsService.reorderWords(newWords.map((word) => ({ id: word.id }))).then((response) => {
        setWords(response.words);
        setLoading(false);
      });
    },
    [words],
  );

  return (
    <TitledCard title="Lista słów">
      <Flex className="mb-3" alignItems="center" justifyContent="end" mobileFlex={false}>
        <div>
          <Link to="new">
            <Button type="primary" icon={<PlusCircleOutlined />} block>
              Dodaj słowo
            </Button>
          </Link>
        </div>
      </Flex>
      <DndProvider backend={HTML5Backend}>
        <Table
          rowKey="id"
          dataSource={words}
          columns={columns}
          bordered
          loading={loading}
          pagination={false}
          components={components}
          // @ts-ignore
          onRow={(_record, index) => ({
            index,
            moveRow,
          })}
        />
      </DndProvider>
    </TitledCard>
  );
};
export default WordsList;
