import { useState } from 'react';

import { Spin } from 'antd';
import { useParams } from 'react-router-dom';

import WordForm from './word-form';

import TitledCard from '../../../layout-components/titled-card';

const EditWord: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const params = useParams();
  const wordId = Number(params.wordId);

  return (
    <Spin spinning={loading}>
      <TitledCard title="Edycja słowa" backUrl="..">
        <WordForm wordId={wordId} setLoading={setLoading} />
      </TitledCard>
    </Spin>
  );
};
export default EditWord;
