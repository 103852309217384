import { useCallback, useEffect, useState } from 'react';

import { ArrowLeftOutlined, MenuOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Table, notification, Button, Divider, Popconfirm } from 'antd';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Link, useParams } from 'react-router-dom';

import type PictureType from '../../../../../typings/map/PictureType';
import type PhotographerType from '../../../../../typings/photoBooth/PhotographerType';

import photographersService from '../../../../../services/photoBooth/photographers-service';
import picturesService from '../../../../../services/photoBooth/pictures-service';
import TitledCard from '../../../../layout-components/titled-card';
import Flex from '../../../../layouts/flex';
import DraggableBodyRow from '../../../../utils/draggable-body-row';

const PicturesList: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [photographer, setPhotographer] = useState<PhotographerType>();
  const [pictures, setPictures] = useState<Array<PictureType>>([]);
  const params = useParams();
  const photographerId = Number(params.photographerId);

  const reloadList = useCallback(() => {
    picturesService.getPictures(photographerId).then((response) => {
      setPictures(response.pictures);
      setLoading(false);
    });
  }, [photographerId]);

  useEffect(() => {
    photographersService.getPhotographer(photographerId).then((response) => {
      setPhotographer(response.photographer);
      reloadList();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photographerId]);

  const removeItem = useCallback(
    (record: PictureType) => {
      setLoading(true);
      picturesService
        .deletePicture(photographerId, record.id)
        .then(() => {
          notification.success({
            message: 'Usunięto zdjęcie z biografii fotografa',
            description: (
              <>
                Zdjęcie <strong>{record.id}</strong> zostało usuniętę z biografii fotografa{' '}
                <strong>{photographer?.title_pl}</strong>.
              </>
            ),
          });
          reloadList();
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [photographer, photographerId, reloadList],
  );

  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      width: 50,
      className: 'drag-visible',
      render: () => <MenuOutlined />,
      align: 'center' as const,
    },
    {
      title: 'Zdjęcie',
      dataIndex: 'thumb_image_url',
      render: (imageUrl: string | null, record: PictureType) =>
        imageUrl ? <img src={imageUrl} alt={record.description_pl} /> : null,
      align: 'center' as const,
      width: 200,
    },
    {
      title: 'Opis',
      dataIndex: 'description_pl',
    },
    {
      title: 'Opis (EN)',
      dataIndex: 'description_en',
    },
    {
      title: 'Operacje',
      key: 'action',
      width: 200,
      align: 'center' as const,
      render: (_text: string, record: PictureType) => (
        <span>
          <Link to={`${record.id}/edit`}>
            <Button type="primary" size="small">
              Edycja
            </Button>
          </Link>

          <Divider type="vertical" />

          <Popconfirm
            title={
              <>
                Czy na pewno usunąć zdjęcie <strong>{record.id}</strong> z biografii fotografa{' '}
                <strong>{photographer?.title_pl}</strong>?
              </>
            }
            onConfirm={() => removeItem(record)}
            okText="Tak"
            cancelText="Nie"
          >
            <Button type="primary" size="small" danger>
              Usuń
            </Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = pictures[dragIndex];
      const newPictures = update(pictures, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });

      setLoading(true);
      picturesService
        .reorderPictures(
          photographerId,
          newPictures.map((picture) => ({ id: picture.id })),
        )
        .then((response) => {
          setPictures(response.pictures);
          setLoading(false);
        });
    },
    [photographerId, pictures],
  );

  return (
    <TitledCard title={`Fotograf ${photographer?.title_pl} - Galeria zdjęć`}>
      <Flex className="mb-3" alignItems="center" justifyContent="end" mobileFlex={false}>
        <div>
          <Link to="../../">
            <Button icon={<ArrowLeftOutlined />}>Powrót</Button>
          </Link>
          <Divider type="vertical" />
          <Link to="new">
            <Button type="primary" icon={<PlusCircleOutlined />}>
              Dodaj zdjęcie
            </Button>
          </Link>
        </div>
      </Flex>
      <DndProvider backend={HTML5Backend}>
        <Table
          rowKey="id"
          dataSource={pictures}
          columns={columns}
          bordered
          loading={loading}
          pagination={false}
          components={components}
          // @ts-ignore
          onRow={(_record, index) => ({
            index,
            moveRow,
          })}
        />
      </DndProvider>
    </TitledCard>
  );
};
export default PicturesList;
