import AntdIcon, {
  BranchesOutlined,
  CameraOutlined,
  CarryOutOutlined,
  ClockCircleOutlined,
  CoffeeOutlined,
  DeploymentUnitOutlined,
  FileTextOutlined,
  FireOutlined,
  FormOutlined,
  GlobalOutlined,
  HeartOutlined,
  HomeOutlined,
  LaptopOutlined,
  MessageOutlined,
  PictureOutlined,
  PrinterOutlined,
  SettingOutlined,
  StarFilled,
  TeamOutlined,
  ThunderboltOutlined,
  UnorderedListOutlined,
  UserOutlined,
  VideoCameraOutlined,
  YoutubeOutlined,
  ToolOutlined,
  SendOutlined,
} from '@ant-design/icons';

import { APP_PREFIX_PATH } from './appConfig';

export interface NavigationItem {
  key: string;
  path?: string;
  title: string;
  icon?: typeof AntdIcon;
  breadcrumb?: boolean;
  submenu: Array<NavigationItem>;
}

const dashBoardNavTree: Array<NavigationItem> = [
  {
    key: 'other',
    title: 'Administracja',
    icon: SettingOutlined,
    submenu: [
      {
        key: 'workstations',
        path: `${APP_PREFIX_PATH}/workstations`,
        title: 'Stanowiska robocze',
        icon: LaptopOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'schedule',
        path: `${APP_PREFIX_PATH}/schedule`,
        title: 'Harmonogram',
        icon: ClockCircleOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'employees',
        path: `${APP_PREFIX_PATH}/employees`,
        title: 'Redaktorzy',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'professions',
    title: 'S1 Fach w ręku',
    icon: ToolOutlined,
    submenu: [
      {
        key: 'professions-restaurant',
        path: `${APP_PREFIX_PATH}/professions/restaurant/spots`,
        title: 'Restauracja',
        icon: CoffeeOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'professions-blacksmith',
        path: `${APP_PREFIX_PATH}/professions/blacksmith/spots`,
        title: 'Kowal',
        icon: ToolOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'professions-tailor',
        path: `${APP_PREFIX_PATH}/professions/tailor/spots`,
        title: 'Szwalnia',
        icon: ToolOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'cookbook',
    title: 'S2 Kuchnia',
    icon: CoffeeOutlined,
    submenu: [
      {
        key: 'cookbook-cuisines',
        path: `${APP_PREFIX_PATH}/cookbook/cuisines`,
        title: 'Kuchnie',
        icon: UnorderedListOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'cookbook-recipes',
        path: `${APP_PREFIX_PATH}/cookbook/recipes`,
        title: 'Przepisy',
        icon: CarryOutOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'cookbook-description',
        path: `${APP_PREFIX_PATH}/cookbook/description`,
        title: 'Wstęp',
        icon: FormOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'pharmacy',
    title: 'S3 Apteka',
    icon: HeartOutlined,
    submenu: [
      {
        key: 'pharmacy-recipes',
        path: `${APP_PREFIX_PATH}/pharmacy/recipes`,
        title: 'Receptury',
        icon: FileTextOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'gallery',
        path: `${APP_PREFIX_PATH}/pictures`,
        title: 'Galeria',
        icon: PictureOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'jan-flatt',
        path: `${APP_PREFIX_PATH}/pharmacy/jan-flatt`,
        title: 'Jan Flatt',
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'szymon-fabian',
        path: `${APP_PREFIX_PATH}/pharmacy/szymon-fabian`,
        title: 'Szymon Fabian',
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'pharmacy-description',
        path: `${APP_PREFIX_PATH}/pharmacy/description`,
        title: 'Opis strony',
        icon: FormOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'projection',
    title: 'S4 Projekcja',
    icon: YoutubeOutlined,
    submenu: [
      {
        key: 'projection-movie',
        path: `${APP_PREFIX_PATH}/projection/movie`,
        title: 'Film',
        icon: CameraOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'language',
    title: 'S5 Nauka Języków',
    icon: GlobalOutlined,
    submenu: [
      {
        key: 'language-dialogues',
        path: `${APP_PREFIX_PATH}/language/dialogues`,
        title: 'Dialogi',
        icon: MessageOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'language-words',
        path: `${APP_PREFIX_PATH}/language/words`,
        title: 'Słowa',
        icon: MessageOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'language-intro',
        path: `${APP_PREFIX_PATH}/language/description`,
        title: 'Wstęp',
        icon: FileTextOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'legends',
    title: 'S6 Legendy',
    icon: StarFilled,
    submenu: [
      {
        key: 'legends-pictures',
        path: `${APP_PREFIX_PATH}/legends/pictures`,
        title: 'Zdjęcia',
        icon: StarFilled,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'photobooth',
    title: 'S7 Fotobudka',
    icon: CameraOutlined,
    submenu: [
      {
        key: 'photobooth-photographers',
        path: `${APP_PREFIX_PATH}/photobooth/photographers`,
        title: 'Fotografowie',
        icon: CameraOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'photobooth-description',
        path: `${APP_PREFIX_PATH}/photobooth/description`,
        title: 'Wstęp',
        icon: FormOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'shopwindow',
    title: 'S7B Witryna',
    icon: VideoCameraOutlined,
    submenu: [
      {
        key: 'shopwindow-movie',
        path: `${APP_PREFIX_PATH}/shopwindow/movie`,
        title: 'Film',
        icon: VideoCameraOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'development',
    title: 'S10 Rozwój',
    icon: DeploymentUnitOutlined,
    submenu: [
      {
        key: 'development-movies',
        path: `${APP_PREFIX_PATH}/development/movies`,
        title: 'Filmy',
        icon: VideoCameraOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'printing',
    title: 'S13 Drukarnia',
    icon: PrinterOutlined,
    submenu: [
      {
        key: 'printing-dialogues',
        path: `${APP_PREFIX_PATH}/printing/history`,
        title: 'Historia',
        icon: FileTextOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'printing-words',
        path: `${APP_PREFIX_PATH}/printing/technology`,
        title: 'Technologia',
        icon: FileTextOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'industry',
    title: 'S14 Przemysł',
    icon: ThunderboltOutlined,
    submenu: [
      {
        key: 'industry-locations',
        path: `${APP_PREFIX_PATH}/industry/locations`,
        title: 'Miejsca',
        icon: HomeOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'industry-description',
        path: `${APP_PREFIX_PATH}/industry/description`,
        title: 'Wstęp',
        icon: FormOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'war',
    title: 'S15 Wojna',
    icon: FireOutlined,
    submenu: [
      {
        key: 'war-movie',
        path: `${APP_PREFIX_PATH}/war/movie`,
        title: 'Film',
        icon: VideoCameraOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'map',
    title: 'S16 Ścieżka zwiedzania',
    icon: BranchesOutlined,
    submenu: [
      {
        key: 'map-paths',
        path: `${APP_PREFIX_PATH}/map/paths`,
        title: 'Ścieżki zwiedzania',
        icon: BranchesOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'map-airport',
        path: `${APP_PREFIX_PATH}/map/airport`,
        title: 'Lotnisko Modlin',
        icon: SendOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
