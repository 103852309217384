import { useState } from 'react';

import { Spin } from 'antd';
import { useParams } from 'react-router-dom';

import PhotographerForm from './photographer-form';

import TitledCard from '../../../layout-components/titled-card';

const EditPhotographer: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const params = useParams<{ photographerId: string }>();
  const photographerId = Number(params.photographerId);

  return (
    <Spin spinning={loading}>
      <TitledCard title="Edycja biografii fotografa" backUrl="..">
        <PhotographerForm photographerId={photographerId} setLoading={setLoading} />
      </TitledCard>
    </Spin>
  );
};
export default EditPhotographer;
