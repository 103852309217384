import { useEffect, useState } from 'react';

import { Spin } from 'antd';
import { useParams } from 'react-router-dom';

import SentenceForm from './sentence-form';

import type DialogueType from '../../../../../typings/language/DialogueType';

import dialoguesService from '../../../../../services/language/dialogues-service';
import TitledCard from '../../../../layout-components/titled-card';

const NewSentence: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [dialogue, setDialogue] = useState<DialogueType>();
  const params = useParams();
  const dialogueId = Number(params.dialogueId);

  useEffect(() => {
    dialoguesService.getDialogue(dialogueId).then((response) => {
      setDialogue(response.dialogue);
    });
  }, [dialogueId]);

  return (
    <Spin spinning={loading}>
      <TitledCard title={`Dialog ${dialogue?.title_pl} - Nowa fraza`} backUrl="..">
        <SentenceForm setLoading={setLoading} dialogueId={dialogueId} />
      </TitledCard>
    </Spin>
  );
};
export default NewSentence;
