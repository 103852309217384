import { useEffect, useState } from 'react';

import { Spin } from 'antd';
import { useParams } from 'react-router-dom';

import SentenceForm from './sentence-form';

import type DialogueType from '../../../../../typings/language/DialogueType';

import dialoguesService from '../../../../../services/language/dialogues-service';
import TitledCard from '../../../../layout-components/titled-card';

const EditSentence: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [dialogue, setDialogue] = useState<DialogueType>();

  const params = useParams();
  const dialogueId = Number(params.dialogueId);
  const sentenceId = Number(params.sentenceId);

  useEffect(() => {
    dialoguesService.getDialogue(dialogueId).then((response) => {
      setDialogue(response.dialogue);
    });
  }, [dialogueId, sentenceId]);

  return (
    <Spin spinning={loading}>
      <TitledCard title={`Dialog ${dialogue?.title_pl} - Edycja frazy`} backUrl="..">
        <SentenceForm dialogueId={dialogueId} sentenceId={sentenceId} setLoading={setLoading} />
      </TitledCard>
    </Spin>
  );
};
export default EditSentence;
