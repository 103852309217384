import { CSSProperties, useRef } from 'react';

import { useDrop, useDrag } from 'react-dnd';

const type = 'DraggableBodyRow';

interface DragObject {
  index: number;
}

interface DropResult {}

interface CollectedProps {
  isOver?: boolean;
  dropClassName?: string;
  isDragging?: boolean;
}

const DraggableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}: {
  index: number;
  className: string;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  style: CSSProperties;
}) => {
  const ref = useRef<HTMLTableRowElement | null>(null);
  const [{ isOver, dropClassName }, drop] = useDrop<DragObject, DropResult, CollectedProps>({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return { isOver: monitor.isOver() };
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item: DragObject) => {
      moveRow(item.index, index);
      return undefined;
    },
  });
  const [, drag] = useDrag<DragObject, DropResult, CollectedProps>({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    />
  );
};

export default DraggableBodyRow;
