import type SentenceType from '../../typings/language/SentenceType';

import { deleteRequest, getRequest, postRequest, putRequest } from '../../api/api-client';

interface SentencesResponse {
  sentences: Array<SentenceType>;
}

interface SentenceResponse {
  sentence: SentenceType;
}

export interface SentenceParams {
  name_pl: string;
  name_en: string;
  author_name: string;
}

const getSentences = async (dialogueId: number): Promise<SentencesResponse> => {
  return getRequest(`/language/dialogues/${dialogueId}/sentences`);
};

const getSentence = async (dialogueId: number, sentenceId: number): Promise<SentenceResponse> => {
  return getRequest(`/language/dialogues/${dialogueId}/sentences/${sentenceId}`);
};

const createSentence = async (
  dialogueId: number,
  params: SentenceParams,
): Promise<SentenceResponse> => {
  return postRequest(`/language/dialogues/${dialogueId}/sentences`, { sentence: params });
};

const updateSentence = async (
  dialogueId: number,
  sentenceId: number,
  params: SentenceParams,
): Promise<SentenceResponse> => {
  return putRequest(`/language/dialogues/${dialogueId}/sentences/${sentenceId}`, {
    sentence: params,
  });
};
const deleteSentence = async (dialogueId: number, sentenceId: number): Promise<void> => {
  return deleteRequest(`/language/dialogues/${dialogueId}/sentences/${sentenceId}`);
};

const sentencesService = {
  getSentences,
  getSentence,
  createSentence,
  updateSentence,
  deleteSentence,
};

export default sentencesService;
