import { useState, useEffect } from 'react';

import { Form, Input, Button, Space, notification, Select } from 'antd';
import { useNavigate } from 'react-router-dom';

import type LanguageType from '../../../../typings/language/LanguageType';

import wordsService, { WordParams } from '../../../../services/language/words-service';
import ErrorsType from '../../../../typings/ErrorsType';

interface Props {
  wordId?: number;
  setLoading: (value: boolean) => void;
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 14 },
};

const WordForm: React.FC<Props> = ({ wordId, setLoading }) => {
  const [languages, setLanguages] = useState<Array<LanguageType>>([]);
  const [form] = Form.useForm<WordParams>();
  const [errors, setErrors] = useState<Record<string, Array<string>>>({});
  const navigate = useNavigate();

  useEffect(() => {
    wordsService.getLanguages().then((response) => {
      setLanguages(response.languages);
    });
  }, []);

  useEffect(() => {
    if (wordId) {
      wordsService.getWord(wordId).then((response) => {
        form.setFieldsValue(response.word);
        setLoading(false);
      });
    }
  }, [wordId, form, setLoading]);

  const catchErrors = (e: ErrorsType) => {
    if (e.statusCode === 422) {
      setErrors(e.errors || {});
    } else {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw e;
    }
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  const onFinish = (values: WordParams) => {
    setLoading(true);
    setErrors({});

    if (wordId) {
      wordsService
        .updateWord(wordId, values)
        .then(({ word }) => {
          notification.success({
            message: 'Zapisano słowo w słowniku',
            description: (
              <>
                Dane słowa <strong>{word.name_pl}</strong> zostały zaktualizowane.
              </>
            ),
          });

          navigate('./../../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    } else {
      wordsService
        .createWord(values)
        .then(({ word }) => {
          notification.success({
            message: 'Utworzono słowo w słowniku',
            description: (
              <>
                Nowe słowo <strong>{word.name_pl}</strong> zostało dodane do słownika.
              </>
            ),
          });
          navigate('./../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onFinishFailed = () => {
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  return (
    <Form
      {...layout}
      form={form}
      name="newWordForm"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Nazwa"
        name="name_pl"
        rules={[{ required: true, message: 'Nazwa jest wymagana' }]}
        hasFeedback
        help={errors.name_pl?.[0]}
        validateStatus={errors.name_pl ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Nazwa (EN)"
        name="name_en"
        rules={[{ required: true, message: 'Nazwa jest wymagana' }]}
        hasFeedback
        help={errors.name_en?.[0]}
        validateStatus={errors.name_en ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Podkreślana odmiana"
        name="text_form"
        hasFeedback
        help={errors.text_form?.[0]}
        validateStatus={errors.text_form ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Opis"
        name="description_pl"
        hasFeedback
        help={errors.description_pl?.[0]}
        validateStatus={errors.description_pl ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Opis (EN)"
        name="description_en"
        hasFeedback
        help={errors.description_en?.[0]}
        validateStatus={errors.description_en ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Język źródłowy"
        name="correct_language"
        rules={[{ required: true, message: 'Język źródłowy jest wymagany' }]}
        hasFeedback
        help={errors.cuisine_id?.[0]}
        validateStatus={errors.cuisine_id ? 'error' : undefined}
      >
        <Select>
          {languages.map((language) => (
            <Select.Option key={language.slug} value={language.slug}>
              {language.name_pl}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Space>
          <Button className="mr-2" type="primary" htmlType="submit">
            Zapisz
          </Button>
          <Button className="mr-2" htmlType="button" onClick={() => form.resetFields()}>
            Reset
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
export default WordForm;
