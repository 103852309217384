import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import { Link } from 'react-router-dom';

import PageTitle from './page-title';

import Flex from '../layouts/flex';

interface Props {
  title: string;
  backUrl?: string;
  children: React.ReactNode;
}
const TitledCard: React.FC<Props> = ({ title, backUrl, children }) => {
  return (
    <Card title={title}>
      <PageTitle title={title} />
      {backUrl ? (
        <Flex className="mb-3" alignItems="center" justifyContent="end" mobileFlex={false}>
          <div>
            <Link to={backUrl}>
              <Button icon={<ArrowLeftOutlined />} block size="small">
                Powrót
              </Button>
            </Link>
          </div>
        </Flex>
      ) : null}
      {children}
    </Card>
  );
};

export default TitledCard;
