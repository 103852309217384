import { useState } from 'react';

import { Spin } from 'antd';
import { useParams } from 'react-router-dom';

import EmployeeForm from './employee-form';

import TitledCard from '../../layout-components/titled-card';

const EditEmployee: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const params = useParams<{ employeeId: string }>();
  const employeeId = Number(params.employeeId);

  return (
    <Spin spinning={loading}>
      <TitledCard title="Edycja redaktora" backUrl="..">
        <EmployeeForm employeeId={employeeId} setLoading={setLoading} />
      </TitledCard>
    </Spin>
  );
};
export default EditEmployee;
