import { useCallback, useEffect, useState } from 'react';

import { FilePdfOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Divider, notification, Popconfirm, Table } from 'antd';
import { Link } from 'react-router-dom';

import type PathType from '../../../../typings/map/PathType';

import pathsService from '../../../../services/map/paths-service';
import TitledCard from '../../../layout-components/titled-card';
import Flex from '../../../layouts/flex';

const PathsList: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [paths, setpaths] = useState<Array<PathType>>([]);

  const reloadList = () => {
    pathsService.getPaths().then((response) => {
      setpaths(response.paths);
      setLoading(false);
    });
  };

  useEffect(reloadList, []);

  const removeItem = useCallback((record: PathType) => {
    setLoading(true);
    pathsService
      .deletePath(record.id)
      .then(() => {
        notification.success({
          message: 'Usunięto ścieżkę zwiedzania',
          description: (
            <>
              Ścieżka zwiedzania <strong>{record.name_pl}</strong> została usunięta.
            </>
          ),
        });
        reloadList();
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const columns = [
    {
      title: 'Nazwa',
      dataIndex: 'name_pl',
      render: (name_pl: string, record: PathType) => (
        <Link to={`${record.id}/edit`}>{name_pl}</Link>
      ),
    },
    {
      title: 'Plik PDF',
      key: 'pdf_file',
      width: 150,
      align: 'center' as const,
      render: (_text: string, record: PathType) =>
        record.pdf_file_pl_url ? (
          <a href={record.pdf_file_pl_url} target="_blank" rel="noreferrer">
            <FilePdfOutlined style={{ color: 'red', fontSize: 32 }} />
          </a>
        ) : null,
    },
    {
      title: 'Punkty',
      key: 'path_points',
      width: 200,
      align: 'center' as const,
      render: (_text: string, record: PathType) => (
        <span>
          <Link to={`${record.id}/points`}>
            <Button type="primary" size="small">
              Punkty
            </Button>
          </Link>
        </span>
      ),
    },
    {
      title: 'Operacje',
      key: 'action',
      width: 200,
      align: 'center' as const,
      render: (_text: string, record: PathType) => (
        <span>
          <Link to={`${record.id}/edit`}>
            <Button type="primary" size="small">
              Edycja
            </Button>
          </Link>

          <Divider type="vertical" />

          <Popconfirm
            title={
              <>
                Czy na pewno usunąć ścieżkę zwiedzania <strong>{record.name_pl}</strong>?
              </>
            }
            onConfirm={() => removeItem(record)}
            okText="Tak"
            cancelText="Nie"
          >
            <Button type="primary" size="small" danger>
              Usuń
            </Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <TitledCard title="Lista ścieżek zwiedzania">
      <Flex className="mb-3" alignItems="center" justifyContent="end" mobileFlex={false}>
        <div>
          <Link to="new">
            <Button type="primary" icon={<PlusCircleOutlined />} block>
              Dodaj ścieżkę
            </Button>
          </Link>
        </div>
      </Flex>
      <Table
        rowKey="id"
        dataSource={paths}
        columns={columns}
        bordered
        loading={loading}
        pagination={false}
      />
    </TitledCard>
  );
};
export default PathsList;
