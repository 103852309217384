import { lazy, Suspense } from 'react';

import { Route, Routes } from 'react-router-dom';

import CuisinesRoutes from './cookbook/cuisines/cuisines-routes';
import CookbookDescriptionForm from './cookbook/description-form';
import CookbookRecipesRoutes from './cookbook/recipes/recipes-routes';
import MoviesRoutes from './development/movies-routes';
import EmployeesRoutes from './employees/employees-routes';
import IndustryDescriptionForm from './industry/description-form';
import LocationsRoutes from './industry/locations/locations-routes';
import LanguageDescriptionForm from './language/description-form';
import DialoguesRoutes from './language/dialogues/dialogues-routes';
import WordsRoutes from './language/words/words-routes';
import LegendsPicturesRoutes from './legends/pictures/pictures-routes';
import AirportForm from './map/airport-form';
import PathsRoutes from './map/paths/paths-routes';
import BiographyForm from './pharmacy/biography/biography-form';
import PharmacyDescriptionForm from './pharmacy/description-form';
import PharmacyRecipesRoutes from './pharmacy/recipes/recipes-routes';
import DescriptionForm from './photoBooth/description-form';
import PhotographersRoutes from './photoBooth/photograhers/photographers-routes';
import PicturesRoutes from './pictures/pictures-routes';
import HistoryForm from './printing/history-form';
import TechnologyForm from './printing/technology-form';
import SpotsRoutes from './professions/spots/spots-routes';
import ProjectionMovieForm from './projection/movie-form';
import ScheduleForm from './schedule/schedule-form';
import ShopWindowMovieForm from './shopWindow/movie-form';
import WarMovieForm from './war/movie-form';

const WorkstationsRoutes = lazy(() => import('./workstations/workstations-routes'));

const AppViews: React.FC = () => {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <Routes>
        <Route path="/workstations/*" element={<WorkstationsRoutes />} />
        <Route path="/employees/*" element={<EmployeesRoutes />} />
        <Route path="/professions/:profession/spots/*" element={<SpotsRoutes />} />
        <Route path="/pharmacy/recipes/*" element={<PharmacyRecipesRoutes />} />
        <Route path="/pharmacy/szymon-fabian" element={<BiographyForm slug="szymon-fabian" />} />
        <Route path="/pharmacy/jan-flatt" element={<BiographyForm slug="jan-flatt" />} />
        <Route path="/pharmacy/description" element={<PharmacyDescriptionForm />} />
        <Route path="/pictures/*" element={<PicturesRoutes />} />
        <Route path="/cookbook/cuisines/*" element={<CuisinesRoutes />} />
        <Route path="/cookbook/recipes/*" element={<CookbookRecipesRoutes />} />
        <Route path="/cookbook/description" element={<CookbookDescriptionForm />} />
        <Route path="/language/dialogues/*" element={<DialoguesRoutes />} />
        <Route path="/language/words/*" element={<WordsRoutes />} />
        <Route path="/language/description" element={<LanguageDescriptionForm />} />
        <Route path="/industry/locations/*" element={<LocationsRoutes />} />
        <Route path="/industry/description" element={<IndustryDescriptionForm />} />
        <Route path="/printing/technology" element={<TechnologyForm />} />
        <Route path="/printing/history" element={<HistoryForm />} />
        <Route path="/development/movies/*" element={<MoviesRoutes />} />
        <Route path="/map/paths/*" element={<PathsRoutes />} />
        <Route path="/map/airport" element={<AirportForm />} />
        <Route path="/photobooth/photographers/*" element={<PhotographersRoutes />} />
        <Route path="/photobooth/description" element={<DescriptionForm />} />
        <Route path="/schedule" element={<ScheduleForm />} />
        <Route path="/projection/movie" element={<ProjectionMovieForm />} />
        <Route path="/legends/pictures/*" element={<LegendsPicturesRoutes />} />
        <Route path="/shopwindow/movie" element={<ShopWindowMovieForm />} />
        <Route path="/war/movie" element={<WarMovieForm />} />
      </Routes>
    </Suspense>
  );
};

export default AppViews;
