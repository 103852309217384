import { Helmet } from 'react-helmet';

interface Props {
  title: string;
}
const PageTitle: React.FC<Props> = ({ title }) => {
  return (
    <Helmet>
      <title>{title} - NowyDwór</title>
    </Helmet>
  );
};

export default PageTitle;
