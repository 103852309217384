import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

type BreakpointList = Partial<Record<Breakpoint, boolean>>;

function getBreakPoint(screens: BreakpointList): Array<Breakpoint> {
  const breakpoints: Array<Breakpoint> = [];

  Object.keys(screens).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(screens, key)) {
      const element = screens[key as Breakpoint];
      if (element) {
        breakpoints.push(key as Breakpoint);
      }
    }
  });
  return breakpoints;
}

export default getBreakPoint;
