import type EmployeeType from '../typings/EmployeeType';

import { apiRequest, BEARER_TOKEN_STORAGE_KEY, getRequest } from '../api/api-client';

interface EmployeeResponse {
  employee: EmployeeType;
}

interface LoginParams {
  email: string;
  password: string;
}

async function loginEmployee(params: LoginParams): Promise<EmployeeResponse> {
  const { response, headers } = await apiRequest<Promise<EmployeeResponse>>(
    '/auth/login',
    { employee: params },
    'POST',
  );
  const authToken = headers.get('Authorization');
  if (authToken !== null) {
    localStorage.setItem(BEARER_TOKEN_STORAGE_KEY, authToken);
  }
  return response;
}

async function logoutEmployee(): Promise<void> {
  localStorage.removeItem(BEARER_TOKEN_STORAGE_KEY);
}

function getProfile(): Promise<EmployeeResponse> {
  return getRequest('/auth/profile');
}

const authService = {
  loginEmployee,
  logoutEmployee,
  getProfile,
};

export default authService;
