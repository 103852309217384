import React from 'react';

import AntdIcon from '@ant-design/icons';

interface Props {
  type: typeof AntdIcon;
  className?: string;
}

const Icon: React.FC<Props> = ({ type, className }) => {
  return <>{React.createElement(type, { className })}</>;
};

export default Icon;
