import { Suspense } from 'react';

import { Routes, Route } from 'react-router-dom';

import DialoguesList from './dialogues-list';
import EditDialogue from './edit-dialogue';
import NewDialogue from './new-dialogue';
import EditSentence from './sentences/edit-sentence';
import NewSentence from './sentences/new-sentence';
import SentencesList from './sentences/sentences-list';

const DialoguesRoutes: React.FC = () => {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <Routes>
        <Route path="/new" element={<NewDialogue />} />
        <Route path="/:dialogueId/edit" element={<EditDialogue />} />
        <Route path="/:dialogueId/sentences">
          <Route path="/:dialogueId/sentences/new" element={<NewSentence />} />
          <Route path="/:dialogueId/sentences/:sentenceId/edit" element={<EditSentence />} />
          <Route index element={<SentencesList />} />
        </Route>
        <Route index element={<DialoguesList />} />
      </Routes>
    </Suspense>
  );
};

export default DialoguesRoutes;
