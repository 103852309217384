import { useCallback, useEffect, useState } from 'react';

import { PlusCircleOutlined } from '@ant-design/icons';
import { Table, notification, Button, Divider, Popconfirm } from 'antd';
import { Link, useParams } from 'react-router-dom';

import type DialogueType from '../../../../../typings/language/DialogueType';
import type SentenceType from '../../../../../typings/language/SentenceType';

import dialoguesService from '../../../../../services/language/dialogues-service';
import sentencesService from '../../../../../services/language/sentences-service';
import TitledCard from '../../../../layout-components/titled-card';
import Flex from '../../../../layouts/flex';

const SentencesList: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [dialogue, setDialogue] = useState<DialogueType>();
  const [sentences, setSentences] = useState<Array<SentenceType>>([]);
  const params = useParams();
  const dialogueId = Number(params.dialogueId);

  const reloadList = useCallback(() => {
    sentencesService.getSentences(dialogueId).then((response) => {
      setSentences(response.sentences);
      setLoading(false);
    });
  }, [dialogueId]);

  useEffect(() => {
    dialoguesService.getDialogue(dialogueId).then((response) => {
      setDialogue(response.dialogue);
      reloadList();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogueId]);

  const removeItem = useCallback(
    (record: SentenceType) => {
      setLoading(true);
      sentencesService
        .deleteSentence(dialogueId, record.id)
        .then(() => {
          notification.success({
            message: 'Usunięto frazę z dialogu',
            description: (
              <>
                Fraza <strong>{record.text_pl}</strong> została usunięta z dialogu{' '}
                <strong>{dialogue?.title_pl}</strong>.
              </>
            ),
          });
          reloadList();
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [dialogue, dialogueId, reloadList],
  );

  const columns = [
    {
      title: 'Tekst',
      dataIndex: 'text_pl',
      render: (text_pl: string, record: SentenceType) => (
        <Link to={`${record.id}/edit`}>{text_pl}</Link>
      ),
    },
    {
      title: 'Tekst (EN)',
      dataIndex: 'text_en',
    },
    {
      title: 'Autor',
      dataIndex: 'author_name',
      align: 'center' as const,
      width: 250,
    },
    {
      title: 'Operacje',
      key: 'action',
      width: 200,
      align: 'center' as const,
      render: (_text: string, record: SentenceType) => (
        <span>
          <Link to={`${record.id}/edit`}>
            <Button type="primary" size="small">
              Edycja
            </Button>
          </Link>

          <Divider type="vertical" />

          <Popconfirm
            title={
              <>
                Czy na pewno usunąć frazę <strong>{record.text_pl}</strong> z dialogu{' '}
                <strong>{dialogue?.title_pl}</strong>?
              </>
            }
            onConfirm={() => removeItem(record)}
            okText="Tak"
            cancelText="Nie"
          >
            <Button type="primary" size="small" danger>
              Usuń
            </Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <TitledCard title={`Dialog ${dialogue?.title_pl} - Lista fraz`}>
      <Flex className="mb-3" alignItems="center" justifyContent="end" mobileFlex={false}>
        <div>
          <Link to="new">
            <Button type="primary" icon={<PlusCircleOutlined />} block>
              Dodaj frazę
            </Button>
          </Link>
        </div>
      </Flex>
      <Table
        rowKey="id"
        dataSource={sentences}
        columns={columns}
        bordered
        loading={loading}
        pagination={false}
      />
    </TitledCard>
  );
};
export default SentencesList;
