import { ArrowLeftOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';

import Flex from './flex';
import MenuContent from './menu-content';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { toggleMobileNav } from '../../redux/slices/themeSlice';

const MobileNav: React.FC = () => {
  const { mobileNav } = useAppSelector((state) => state.theme);
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(toggleMobileNav(false));
  };
  return (
    <Drawer
      placement="left"
      closable={false}
      onClose={onClose}
      visible={mobileNav}
      bodyStyle={{ padding: 5 }}
      width={300}
    >
      <Flex flexDirection="column" className="h-100">
        <Flex justifyContent="between" alignItems="center">
          LOGO
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <div className="nav-close" onClick={onClose}>
            <ArrowLeftOutlined />
          </div>
        </Flex>
        <div className="mobile-nav-menu">
          <MenuContent isMobile />
        </div>
      </Flex>
    </Drawer>
  );
};
export default MobileNav;
