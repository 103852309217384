import { useState } from 'react';

import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import Icon from './icon';

import navigationConfig, { NavigationItem } from '../../configs/navigationConfig';
import { useAppDispatch } from '../../redux/hooks';
import { toggleMobileNav } from '../../redux/slices/themeSlice';

interface Props {
  isMobile: boolean;
}

const getRouteInfo = (navTree: Array<NavigationItem>, path: string): NavigationItem | undefined => {
  const currentPath = path.endsWith('/') ? path.substring(0, path.length - 1) : path;
  let route = navTree.find((item) => item.path === currentPath);

  if (route) {
    return route;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const p in navTree) {
    // eslint-disable-next-line no-prototype-builtins
    if (navTree.hasOwnProperty(p) && typeof navTree[p] === 'object') {
      route = getRouteInfo(navTree[p].submenu, path);
      if (route) {
        return route;
      }
    }
  }

  return navTree.find((item) => getRouteInfo(item.submenu, path));
};

const getCurrentOpenKeys = (path: string) => {
  const currentPath = path.endsWith('/') ? path.substring(0, path.length - 1) : path;

  const rootNavigation = navigationConfig.find(
    (item) => item.submenu && item.submenu.find((item2) => item2.path === currentPath),
  );
  return rootNavigation ? [rootNavigation.key] : [];
};

const MenuContent: React.FC<Props> = ({ isMobile }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState<Array<string>>(getCurrentOpenKeys(location.pathname));
  const rootSubmenuKeys = navigationConfig.map((item) => item.key);

  const closeMobileNav = () => {
    if (isMobile) {
      dispatch(toggleMobileNav(false));
    }
  };

  const onOpenChange = (newOpenKeys: Array<string>) => {
    const latestOpenKey = newOpenKeys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(newOpenKeys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const activeRoute = getRouteInfo(navigationConfig, location.pathname);

  return (
    <Menu
      theme="dark"
      mode="inline"
      inlineIndent={20}
      style={{ height: '100%', borderRight: 0 }}
      // defaultSelectedKeys={[navigationConfig[0]?.key]}
      defaultSelectedKeys={activeRoute ? [activeRoute.key] : undefined}
      onOpenChange={onOpenChange}
      openKeys={openKeys}
    >
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <Menu.SubMenu
            key={menu.key}
            title={menu.title}
            icon={menu.icon ? <Icon type={menu.icon} /> : null}
          >
            {menu.submenu.map((subMenuFirst) => (
              <Menu.Item key={subMenuFirst.key}>
                {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                <span>{subMenuFirst.title}</span>
                {subMenuFirst.path ? (
                  <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
                ) : null}
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu.icon} /> : null}
            <span>{menu.title}</span>
            {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
          </Menu.Item>
        ),
      )}
    </Menu>
  );
};

export default MenuContent;
