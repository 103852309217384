import ApiError from './api-error';

export const BEARER_TOKEN_STORAGE_KEY = 'nd-auth-token';

interface OptionsType {
  method: string;
  headers: Headers;
  body?: FormData | string;
}

const apiHost = process.env.REACT_APP_API_HOST;

function apiUrl(relativeUrl: string): string {
  return `${apiHost}${`/api/${relativeUrl}`.replace(/\/\//g, '/')}`;
}

async function rawRequest(
  url: string,
  params?: Record<string, unknown> | FormData,
  method = 'GET',
): Promise<Response> {
  const token =
    typeof localStorage !== 'undefined' ? localStorage.getItem(BEARER_TOKEN_STORAGE_KEY) : null;

  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  if (token) {
    headers.Authorization = `${token}`;
  }
  const options: OptionsType = {
    method,
    headers: new Headers(headers),
  };

  if (typeof FormData !== 'undefined' && params instanceof FormData) {
    options.headers.delete('Content-Type');
    options.body = params;
  } else if (params != null) {
    options.body = JSON.stringify(params);
  }
  return fetch(apiUrl(url), options);
}

export async function apiRequest<ResponseType>(
  url: string,
  params?: Record<string, unknown> | FormData,
  method = 'GET',
): Promise<{ response: ResponseType; headers: Headers }> {
  const response = await rawRequest(url, params, method);
  if (response.ok) {
    const jsonResponse = await response.json();
    return { response: jsonResponse, headers: response.headers };
  }
  const statusCode = response.status;
  const errorResponse = await response.json();

  return Promise.reject(new ApiError({ statusCode, ...errorResponse }));
}

async function getRequest<ResponseType>(url: string): Promise<ResponseType> {
  return (await apiRequest<ResponseType>(url)).response;
}

async function postRequest<ResponseType>(url: string, params = {}): Promise<ResponseType> {
  return (await apiRequest<ResponseType>(url, params, 'POST')).response;
}

async function putRequest<ResponseType>(url: string, params = {}): Promise<ResponseType> {
  return (await apiRequest<ResponseType>(url, params, 'PUT')).response;
}

async function deleteRequest<ResponseType>(url: string, params = {}): Promise<ResponseType> {
  return (await apiRequest<ResponseType>(url, params, 'DELETE')).response;
}

export { rawRequest, getRequest, postRequest, putRequest, deleteRequest };
