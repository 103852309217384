import { useState } from 'react';

import { Spin } from 'antd';
import { useParams } from 'react-router-dom';

import MovieForm from './movie-form';

import TitledCard from '../../layout-components/titled-card';

const EditLocation: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const { movieId } = useParams();

  return (
    <Spin spinning={loading}>
      <TitledCard title="Edycja filmu" backUrl="..">
        <MovieForm movieId={Number(movieId)} setLoading={setLoading} />
      </TitledCard>
    </Spin>
  );
};
export default EditLocation;
