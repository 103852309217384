import { serialize } from 'object-to-formdata';

import type MovieType from '../../typings/MovieType';

import { getRequest, postRequest, putRequest } from '../../api/api-client';

interface MovieResponse {
  movie: MovieType;
}

export interface MovieParams {
  video_pl: File | null;
}

const getMovie = async (): Promise<MovieResponse> => {
  return getRequest('/movies/shopwindow/by-name/movie');
};

const createMovie = async (params: MovieParams): Promise<MovieResponse> => {
  return postRequest('/movies/shopwindow', serialize({ movie: { ...params, name_pl: 'movie' } }));
};

const updateMovie = async (movieId: number, params: MovieParams): Promise<MovieResponse> => {
  return putRequest(
    `/movies/shopwindow/${movieId}`,
    serialize({ movie: { ...params, name_pl: 'movie' } }),
  );
};

const moviesService = {
  getMovie,
  createMovie,
  updateMovie,
};

export default moviesService;
