import { serialize } from 'object-to-formdata';

import type StaticPage from '../../typings/StaticPage';

import { getRequest, putRequest } from '../../api/api-client';

interface StaticPageResponse {
  static_page: StaticPage;
}

export interface StaticPageParams {
  title_pl: string;
  title_en: string;
  slug: string;
  sections: Array<
    | {
        id: number;
        title_pl: string;
        title_en: string;
        content_pl: string;
        content_en: string;
        image: File | null;
        image_description_pl: string;
        image_description_en: string;
        _destroy: undefined;
      }
    | {
        id: number;
        _destroy?: boolean;
        image?: undefined;
      }
  >;
}

const getTechnology = async (): Promise<StaticPageResponse> => {
  return getRequest('/static-pages/printing/technology');
};

const updateTechnology = async (params: StaticPageParams): Promise<StaticPageResponse> => {
  return putRequest('/static-pages/printing/technology', serialize({ static_page: params }));
};
const getHistory = async (): Promise<StaticPageResponse> => {
  return getRequest('/static-pages/printing/history');
};

const updateHistory = async (params: StaticPageParams): Promise<StaticPageResponse> => {
  return putRequest('/static-pages/printing/history', serialize({ static_page: params }));
};

const pritingService = {
  getTechnology,
  updateTechnology,
  getHistory,
  updateHistory,
};

export default pritingService;

export const staticPageToFormFields = (staticPage: StaticPage) => ({
  title_pl: staticPage.title_pl,
  title_en: staticPage.title_en,
  slug: staticPage.slug,
  sections: staticPage.sections.map((section) => ({
    id: section.id,
    title_pl: section.title_pl,
    title_en: section.title_en,
    content_pl: section.content_pl,
    content_en: section.content_en,
    image_description_pl: section.image_description_pl,
    image_description_en: section.image_description_en,
  })),
});
