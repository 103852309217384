import { useCallback, useEffect, useState } from 'react';

import { MenuOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Divider, notification, Popconfirm, Table } from 'antd';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Link } from 'react-router-dom';

import type PictureType from '../../../../typings/legends/PictureType';

import picturesService from '../../../../services/legends/pictures-service';
import TitledCard from '../../../layout-components/titled-card';
import Flex from '../../../layouts/flex';
import DraggableBodyRow from '../../../utils/draggable-body-row';

const PicturesList: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [pictures, setPictures] = useState<Array<PictureType>>([]);

  const reloadList = () => {
    picturesService.getPictures().then((response) => {
      setPictures(response.pictures);
      setLoading(false);
    });
  };

  useEffect(reloadList, []);

  const removeItem = useCallback((record: PictureType) => {
    setLoading(true);
    picturesService
      .deletePicture(record.id)
      .then(() => {
        notification.success({
          message: 'Usunięto zdjęcie z biografii fotografa',
          description: (
            <>
              Zdjęcie <strong>{record.id}</strong> zostało usuniętę z aplikacji Legendy.
            </>
          ),
        });
        reloadList();
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      width: 50,
      className: 'drag-visible',
      render: () => <MenuOutlined />,
      align: 'center' as const,
    },
    {
      title: 'Zdjęcie (PL)',
      dataIndex: 'thumb_image_pl_url',
      render: (imageUrl: string | null) => (imageUrl ? <img src={imageUrl} alt="" /> : null),
      align: 'center' as const,
      width: 200,
    },
    {
      title: 'Zdjęcie (EN)',
      dataIndex: 'thumb_image_en_url',
      render: (imageUrl: string | null) => (imageUrl ? <img src={imageUrl} alt="" /> : null),
      align: 'center' as const,
      width: 200,
    },
    {
      title: 'Czas wyświetlania (s)',
      dataIndex: 'duration',
      align: 'center' as const,
      width: 100,
    },
    {
      title: 'Operacje',
      key: 'action',
      width: 200,
      align: 'center' as const,
      render: (_text: string, record: PictureType) => (
        <span>
          <Link to={`${record.id}/edit`}>
            <Button type="primary" size="small">
              Edycja
            </Button>
          </Link>

          <Divider type="vertical" />

          <Popconfirm
            title={
              <>
                Czy na pewno usunąć zdjęcie <strong>{record.id}</strong> z aplikacji Legendy?
              </>
            }
            onConfirm={() => removeItem(record)}
            okText="Tak"
            cancelText="Nie"
          >
            <Button type="primary" size="small" danger>
              Usuń
            </Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = pictures[dragIndex];
      const newPictures = update(pictures, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });

      setLoading(true);
      picturesService
        .reorderPictures(newPictures.map((picture) => ({ id: picture.id })))
        .then((response) => {
          setPictures(response.pictures);
          setLoading(false);
        });
    },
    [pictures],
  );

  return (
    <TitledCard title="Legendy - Galeria zdjęć">
      <Flex className="mb-3" alignItems="center" justifyContent="end" mobileFlex={false}>
        <Link to="new">
          <Button type="primary" icon={<PlusCircleOutlined />}>
            Dodaj zdjęcie
          </Button>
        </Link>
      </Flex>
      <DndProvider backend={HTML5Backend}>
        <Table
          rowKey="id"
          dataSource={pictures}
          columns={columns}
          bordered
          loading={loading}
          pagination={false}
          components={components}
          // @ts-ignore
          onRow={(_record, index) => ({
            index,
            moveRow,
          })}
        />
      </DndProvider>
    </TitledCard>
  );
};
export default PicturesList;
