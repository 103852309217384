import { useCallback, useEffect, useState } from 'react';

import { MenuOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Divider, notification, Popconfirm, Table } from 'antd';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Link, useParams } from 'react-router-dom';

import type PathPointType from '../../../../typings/map/PathPointType';
import type PathType from '../../../../typings/map/PathType';

import pathPointsService from '../../../../services/map/path-points-service';
import pathsService from '../../../../services/map/paths-service';
import TitledCard from '../../../layout-components/titled-card';
import Flex from '../../../layouts/flex';
import DraggableBodyRow from '../../../utils/draggable-body-row';

const PathPointsList: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [path, setPath] = useState<PathType>();
  const [pathPoints, setPathPoints] = useState<Array<PathPointType>>([]);

  const params = useParams();
  const pathId = Number(params.pathId);

  useEffect(() => {
    setLoading(true);
    pathsService.getPath(pathId).then((response) => {
      setPath(response.path);
      pathPointsService.getPathPoints(pathId).then((response2) => {
        setPathPoints(response2.path_points);
        setLoading(false);
      });
    });
  }, [pathId]);

  const removeItem = useCallback(
    (record: PathPointType) => {
      setLoading(true);
      pathPointsService
        .deletePathPoint(pathId, record.id)
        .then(() => {
          notification.success({
            message: 'Usunięto punkt ze ścieżki zwiedzania',
            description: (
              <>
                Punkt zwiedzania <strong>{record.name_pl}</strong> został usunięty.
              </>
            ),
          });
          pathPointsService.getPathPoints(pathId).then((response) => {
            setPathPoints(response.path_points);
            setLoading(false);
          });
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [pathId],
  );

  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      width: 50,
      className: 'drag-visible',
      render: () => <MenuOutlined />,
      align: 'center' as const,
    },
    {
      title: 'Nazwa',
      dataIndex: 'name_pl',
      render: (name_pl: string, record: PathPointType) => (
        <Link to={`${record.id}/edit`}>{name_pl}</Link>
      ),
    },
    {
      title: 'Zdjęcie',
      dataIndex: 'thumb_image_url',
      render: (imageUrl: string | null, record: PathPointType) =>
        imageUrl ? <img src={imageUrl} alt={record.name_pl} /> : null,
      align: 'center' as const,
      width: 200,
    },
    {
      title: 'Operacje',
      key: 'action',
      width: 200,
      align: 'center' as const,
      render: (_text: string, record: PathPointType) => (
        <span>
          <Link to={`${record.id}/edit`}>
            <Button type="primary" size="small">
              Edycja
            </Button>
          </Link>

          <Divider type="vertical" />

          <Popconfirm
            title={
              <>
                Czy na pewno usunąć punkt zwiedzania <strong>{record.name_pl}</strong>?
              </>
            }
            onConfirm={() => removeItem(record)}
            okText="Tak"
            cancelText="Nie"
          >
            <Button type="primary" size="small" danger>
              Usuń
            </Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = pathPoints[dragIndex];
      const newPathPoints = update(pathPoints, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });

      setLoading(true);
      pathPointsService
        .reorderPathPoints(
          pathId,
          newPathPoints.map((pathPoint) => ({ id: pathPoint.id })),
        )
        .then((response) => {
          setPathPoints(response.path_points);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [pathId, pathPoints],
  );

  return (
    <TitledCard title={`Ścieżka zwiedzania ${path?.name_pl} » Lista punktów zwiedzania`}>
      <Flex className="mb-3" alignItems="center" justifyContent="end" mobileFlex={false}>
        <div>
          <Link to="new">
            <Button type="primary" icon={<PlusCircleOutlined />} block>
              Dodaj punkt zwiedzania
            </Button>
          </Link>
        </div>
      </Flex>
      <DndProvider backend={HTML5Backend}>
        <Table
          rowKey="id"
          dataSource={pathPoints}
          columns={columns}
          bordered
          loading={loading}
          pagination={false}
          components={components}
          // @ts-ignore
          onRow={(_record, index) => ({
            index,
            moveRow,
          })}
        />
      </DndProvider>
    </TitledCard>
  );
};
export default PathPointsList;
