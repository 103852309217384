import { useEffect, useState } from 'react';

import { Button, Form, Input, notification, Space } from 'antd';
import { useNavigate } from 'react-router-dom';

import type ErrorsType from '../../../../typings/ErrorsType';

import pathsService, { PathParams } from '../../../../services/map/paths-service';
import FileInput from '../../../utils/file-input';

interface Props {
  pathId?: number;
  setLoading: (value: boolean) => void;
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 14 },
};

const PathForm: React.FC<Props> = ({ pathId, setLoading }) => {
  const [errors, setErrors] = useState<Record<string, Array<string>>>({});

  const [form] = Form.useForm<PathParams>();

  const navigate = useNavigate();

  useEffect(() => {
    if (pathId) {
      pathsService.getPath(pathId).then((response) => {
        form.setFieldsValue(response.path);
        setLoading(false);
      });
    } else {
      form.setFieldsValue({});
    }
  }, [form, pathId, setLoading]);

  const catchErrors = (e: ErrorsType) => {
    if (e.statusCode === 422) {
      setErrors(e.errors || {});
    }
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  const onFinish = (values: PathParams) => {
    setLoading(true);
    setErrors({});

    if (pathId) {
      pathsService
        .updatePath(pathId, values)
        .then(({ path }) => {
          notification.success({
            message: 'Zapisano ścieżkę zwiedzania',
            description: (
              <>
                Dane ścieżki zwiedzania <strong>{path.name_pl}</strong> zostały zaktualizowane.
              </>
            ),
          });
          navigate('../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    } else {
      pathsService
        .createPath(values)
        .then(({ path }) => {
          notification.success({
            message: 'Utworzono ścieżkę zwiedzania',
            description: (
              <>
                Nowa ścieżka zwiedzania <strong>{path.name_pl}</strong> została dodana.
              </>
            ),
          });
          navigate('../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onFinishFailed = () => {
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  return (
    <Form
      {...layout}
      form={form}
      name="newPathForm"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Nazwa"
        name="name_pl"
        rules={[{ required: true, message: 'Nazwa są wymagane' }]}
        hasFeedback
        help={errors.name_pl?.[0]}
        validateStatus={errors.name_pl ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Nazwa (EN)"
        name="name_en"
        hasFeedback
        help={errors.name_en?.[0]}
        validateStatus={errors.name_en ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Plik PDF"
        name="pdf_file_pl"
        hasFeedback
        help={errors.pdf_file_pl?.[0]}
        validateStatus={errors.pdf_file_pl ? 'error' : undefined}
      >
        <FileInput accept="application/pdf" maxCount={1} />
      </Form.Item>

      <Form.Item
        label="Plik PDF (EN)"
        name="pdf_file_en"
        hasFeedback
        help={errors.pdf_file_en?.[0]}
        validateStatus={errors.pdf_file_en ? 'error' : undefined}
      >
        <FileInput accept="application/pdf" maxCount={1} />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Space>
          <Button className="mr-2" type="primary" htmlType="submit">
            Zapisz
          </Button>
          <Button className="mr-2" htmlType="button" onClick={() => form.resetFields()}>
            Reset
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
export default PathForm;
