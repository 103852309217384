import { useState, useEffect } from 'react';

import { Form, Input, Button, Space, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import cuisinesService, { CuisineParams } from '../../../../services/cookbook/cuisines-service';
import ErrorsType from '../../../../typings/ErrorsType';
import TextEditor from '../../../layouts/text-editor';

interface Props {
  cuisineId?: number;
  setLoading: (value: boolean) => void;
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 14 },
};

const CuisineForm: React.FC<Props> = ({ cuisineId, setLoading }) => {
  const [form] = Form.useForm<CuisineParams>();
  const [errors, setErrors] = useState<Record<string, Array<string>>>({});
  const navigate = useNavigate();

  useEffect(() => {
    if (cuisineId) {
      cuisinesService.getCuisine(cuisineId).then((response) => {
        form.setFieldsValue({
          name_pl: response.cuisine.name_pl,
          name_en: response.cuisine.name_en,
          description_pl: response.cuisine.description_pl ?? undefined,
          description_en: response.cuisine.description_en ?? undefined,
        });
        setLoading(false);
      });
    }
  }, [cuisineId, form, setLoading]);

  const catchErrors = (e: ErrorsType) => {
    if (e.statusCode === 422) {
      setErrors(e.errors || {});
    }
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  const onFinish = (values: CuisineParams) => {
    setLoading(true);
    setErrors({});

    if (cuisineId) {
      cuisinesService
        .updateCuisine(cuisineId, values)
        .then(({ cuisine }) => {
          notification.success({
            message: 'Zapisano kuchnię',
            description: (
              <>
                Dane kuchnii <strong>{cuisine.name_pl}</strong> zostały zaktualizowane.
              </>
            ),
          });
          navigate('../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    } else {
      cuisinesService
        .createCuisine(values)
        .then(({ cuisine }) => {
          notification.success({
            message: 'Utworzono kuchnię',
            description: (
              <>
                Nowa kuchnia <strong>{cuisine.name_pl}</strong> została dodana.
              </>
            ),
          });
          navigate('../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onFinishFailed = () => {
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  return (
    <Form
      {...layout}
      form={form}
      name="newCuisineForm"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Nazwa (PL)"
        name="name_pl"
        rules={[{ required: true, message: 'Nazwa jest wymagana' }]}
        hasFeedback
        help={errors.name_pl?.[0]}
        validateStatus={errors.name_pl ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Nazwa (EN)"
        name="name_en"
        hasFeedback
        help={errors.name_en?.[0]}
        validateStatus={errors.name_en ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Opis (PL)"
        name="description_pl"
        hasFeedback
        help={errors.description_pl?.[0]}
        validateStatus={errors.description_pl ? 'error' : undefined}
      >
        <TextEditor />
      </Form.Item>

      <Form.Item
        label="Opis (EN)"
        name="description_en"
        hasFeedback
        help={errors.description_en?.[0]}
        validateStatus={errors.description_en ? 'error' : undefined}
      >
        <TextEditor />
      </Form.Item>

      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Form.Item {...tailLayout}>
        <Space>
          <Button className="mr-2" type="primary" htmlType="submit">
            Zapisz
          </Button>
          <Button className="mr-2" htmlType="button" onClick={() => form.resetFields()}>
            Reset
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
export default CuisineForm;
