import { useEffect, useState } from 'react';

import { useJsApiLoader } from '@react-google-maps/api';
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';

import PathPointForm from './path-point-form';

import type PathType from '../../../../typings/map/PathType';

import pathsService from '../../../../services/map/paths-service';
import TitledCard from '../../../layout-components/titled-card';

const EditPathPoint: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [path, setPath] = useState<PathType>();

  const params = useParams();
  const pathId = Number(params.pathId);
  const pathPointId = Number(params.pathPointId);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY ?? '',
  });

  useEffect(() => {
    setLoading(true);
    pathsService.getPath(pathId).then((response) => {
      setPath(response.path);
    });
  }, [pathId]);

  return (
    <Spin spinning={loading}>
      <TitledCard
        title={`Ścieżka zwiedzania ${path?.name_pl} » Edycja punktu zwiedzania`}
        backUrl=".."
      >
        {path && isLoaded ? (
          <PathPointForm pathId={pathId} pathPointId={pathPointId} setLoading={setLoading} />
        ) : null}
      </TitledCard>
    </Spin>
  );
};
export default EditPathPoint;
