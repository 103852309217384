import type CuisineType from '../../typings/cookbook/CuisineType';

import { deleteRequest, getRequest, postRequest, putRequest } from '../../api/api-client';

interface CuisinesResponse {
  cuisines: Array<CuisineType>;
}

interface CuisineResponse {
  cuisine: CuisineType;
}

export interface CuisineParams {
  name_pl: string;
  name_en: string;
  description_pl: string;
  description_en: string;
}

const getCuisines = async (): Promise<CuisinesResponse> => {
  return getRequest('/cookbook/cuisines');
};

const getCuisine = async (cuisineId: number): Promise<CuisineResponse> => {
  return getRequest(`/cookbook/cuisines/${cuisineId}`);
};

const createCuisine = async (params: CuisineParams): Promise<CuisineResponse> => {
  return postRequest(`/cookbook/cuisines`, { cuisine: params });
};

const updateCuisine = async (
  cuisineId: number,
  params: CuisineParams,
): Promise<CuisineResponse> => {
  return putRequest(`/cookbook/cuisines/${cuisineId}`, { cuisine: params });
};
const deleteCuisine = async (cuisineId: number): Promise<void> => {
  return deleteRequest(`/cookbook/cuisines/${cuisineId}`);
};

const CuisinesService = {
  getCuisines,
  getCuisine,
  createCuisine,
  updateCuisine,
  deleteCuisine,
};

export default CuisinesService;
