import { useEffect, useState } from 'react';

import { Button, Form, Input, notification, Space } from 'antd';
import { useNavigate } from 'react-router-dom';

import type ErrorsType from '../../../typings/ErrorsType';

import picturesService, { PictureParams } from '../../../services/pictures-service';
import FileInput from '../../utils/file-input';

interface Props {
  pictureId?: number;
  setLoading: (value: boolean) => void;
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 14 },
};

const PictureForm: React.FC<Props> = ({ pictureId, setLoading }) => {
  const [defaultImage, setDefaultImage] = useState<string | null>();
  const [errors, setErrors] = useState<Record<string, Array<string>>>({});

  const [form] = Form.useForm<PictureParams>();
  const navigate = useNavigate();

  useEffect(() => {
    if (pictureId) {
      picturesService.getPicture(pictureId).then((response) => {
        form.setFieldsValue(response.picture);
        setDefaultImage(response.picture.thumb_image_url);
        setLoading(false);
      });
    } else {
      setDefaultImage(undefined);
    }
  }, [form, pictureId, setLoading]);

  const catchErrors = (e: ErrorsType) => {
    if (e.statusCode === 422) {
      setErrors(e.errors || {});
    }
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  const onFinish = (values: PictureParams) => {
    setLoading(true);
    setErrors({});

    if (pictureId) {
      picturesService
        .updatePicture(pictureId, values)
        .then(() => {
          notification.success({ message: 'Dane zdjęcia zostały zaktualizowane' });
          navigate('../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    } else {
      picturesService
        .createPicture(values)
        .then(() => {
          notification.success({ message: 'Nowe zdjęcie zostało dodane' });
          navigate('../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onFinishFailed = () => {
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  return (
    <Form
      {...layout}
      form={form}
      name="newPictureForm"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={{ description_pl: '', description_en: '' }}
      autoComplete="off"
    >
      <Form.Item
        label="Zdjęcie"
        name="image"
        hasFeedback
        help={errors.image?.[0]}
        validateStatus={errors.image ? 'error' : undefined}
      >
        <FileInput accept="image/jpeg,image/png" maxCount={1} defaultPreview={defaultImage} />
      </Form.Item>

      <Form.Item
        label="Opis"
        name="description_pl"
        hasFeedback
        help={errors.description_pl?.[0]}
        validateStatus={errors.description_pl ? 'error' : undefined}
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item
        label="Opis (EN)"
        name="description_en"
        hasFeedback
        help={errors.description_en?.[0]}
        validateStatus={errors.description_en ? 'error' : undefined}
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Space>
          <Button className="mr-2" type="primary" htmlType="submit">
            Zapisz
          </Button>
          <Button className="mr-2" htmlType="button" onClick={() => form.resetFields()}>
            Reset
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
export default PictureForm;
