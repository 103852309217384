import { useState } from 'react';

import { Spin } from 'antd';
import { useParams } from 'react-router-dom';

import DialogueForm from './dialogue-form';

import TitledCard from '../../../layout-components/titled-card';

const EditDialogue: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const params = useParams<{ dialogueId: string }>();
  const dialogueId = Number(params.dialogueId);

  return (
    <Spin spinning={loading}>
      <TitledCard title="Edycja dialogu" backUrl="..">
        <DialogueForm dialogueId={dialogueId} setLoading={setLoading} />
      </TitledCard>
    </Spin>
  );
};
export default EditDialogue;
