import React, { useEffect, useState } from 'react';

import { Grid, Layout, notification, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';

import MenuContent from './menu-content';
import MobileNav from './mobile-nav';

import { SIDE_NAV_WIDTH, SIDE_NAV_COLLAPSED_WIDTH } from '../../constants/themeConstants';
import { useAppSelector } from '../../redux/hooks';
import authService from '../../services/auth-service';
import EmployeeType from '../../typings/EmployeeType';
import getBreakPoint from '../../utils/getBreakPoint';
import NavHeader from '../layout-components/nav-header';
import AppViews from '../views/app-views';

import './app-layout.scss';

const { Sider, Content } = Layout;
const { useBreakpoint } = Grid;

const AppLayout: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [employee, setEmployee] = useState<EmployeeType>();
  const screens = getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes('lg');

  const { navCollapsed } = useAppSelector((state) => state.theme);

  const navigate = useNavigate();

  useEffect(() => {
    authService
      .getProfile()
      .then((response) => {
        setEmployee(response.employee);
        setLoading(false);
      })
      .catch(() => {
        notification.error({ message: 'Nie jesteś zalogowany' });
        navigate('/auth');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLayoutGutter = () => {
    if (isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  if (loading) {
    return <Spin />;
  }

  return (
    <Layout>
      <NavHeader isMobile={isMobile} employee={employee} />

      {!isMobile && (
        <Sider
          trigger={null}
          collapsible
          collapsed={navCollapsed}
          className="side-nav side-nav-dark"
          width={SIDE_NAV_WIDTH}
        >
          <MenuContent isMobile={isMobile} />
        </Sider>
      )}
      <Layout className="app-layout" style={{ paddingLeft: getLayoutGutter() }}>
        <div className="app-content">
          <Content>
            <AppViews />
          </Content>
        </div>
      </Layout>
      {isMobile && <MobileNav />}
    </Layout>
  );
};

export default AppLayout;
