import { useState, useEffect } from 'react';

import { Form, Input, Button, Space, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import dialoguesService from '../../../../../services/language/dialogues-service';
import sentencesService, {
  SentenceParams,
} from '../../../../../services/language/sentences-service';
import ErrorsType from '../../../../../typings/ErrorsType';
import DialogueType from '../../../../../typings/language/DialogueType';

interface Props {
  dialogueId: number;
  sentenceId?: number;
  setLoading: (value: boolean) => void;
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 14 },
};

const SentenceForm: React.FC<Props> = ({ dialogueId, sentenceId, setLoading }) => {
  const [dialogue, setDialogue] = useState<DialogueType>();
  const [errors, setErrors] = useState<Record<string, Array<string>>>({});

  const [form] = Form.useForm<SentenceParams>();
  const navigate = useNavigate();

  useEffect(() => {
    dialoguesService.getDialogue(dialogueId).then((response) => {
      setDialogue(response.dialogue);
      if (sentenceId) {
        sentencesService.getSentence(dialogueId, sentenceId).then((response2) => {
          form.setFieldsValue(response2.sentence);
          setLoading(false);
        });
      }
    });
  }, [dialogueId, sentenceId, form, setLoading]);

  const catchErrors = (e: ErrorsType) => {
    if (e.statusCode === 422) {
      setErrors(e.errors || {});
    } else {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw e;
    }
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  const onFinish = (values: SentenceParams) => {
    setLoading(true);
    setErrors({});

    if (sentenceId) {
      sentencesService
        .updateSentence(dialogueId, sentenceId, values)
        .then(({ sentence }) => {
          notification.success({
            message: 'Zapisano frazę do dialogu',
            description: (
              <>
                Dane frazy <strong>{sentence.text_pl}</strong> zostały zaktualizowane.
              </>
            ),
          });
          navigate('./../../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    } else {
      sentencesService
        .createSentence(dialogueId, values)
        .then(({ sentence }) => {
          notification.success({
            message: 'Utworzono frazę do dialogu',
            description: (
              <>
                Fraza <strong>{sentence.text_pl}</strong> została dodana do dialogu{' '}
                <strong>{dialogue?.title_pl}</strong>.
              </>
            ),
          });
          notification.success({ message: 'Nowa fraza została dodana' });
          navigate('./../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onFinishFailed = () => {
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  return (
    <Form
      {...layout}
      form={form}
      name="newSentenceForm"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Treść"
        name="text_pl"
        rules={[{ required: true, message: 'Treść jest wymagana' }]}
        hasFeedback
        help={errors.text_pl?.[0]}
        validateStatus={errors.text_pl ? 'error' : undefined}
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item
        label="Treść (EN)"
        name="text_en"
        rules={[{ required: true, message: 'Treść jest wymagana' }]}
        hasFeedback
        help={errors.text_en?.[0]}
        validateStatus={errors.text_en ? 'error' : undefined}
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item
        label="Autor"
        name="author_name"
        hasFeedback
        help={errors.author_name?.[0]}
        validateStatus={errors.author_name ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Space>
          <Button className="mr-2" type="primary" htmlType="submit">
            Zapisz
          </Button>
          <Button className="mr-2" htmlType="button" onClick={() => form.resetFields()}>
            Reset
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
export default SentenceForm;
