import { useCallback, useEffect, useState } from 'react';

import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { Button, Form, Input, notification, Space } from 'antd';
import { useNavigate } from 'react-router-dom';

import type ErrorsType from '../../../../typings/ErrorsType';

import pathPointsService, { PathPointParams } from '../../../../services/map/path-points-service';
import PathPointType from '../../../../typings/map/PathPointType';
import FileInput from '../../../utils/file-input';

interface Props {
  pathId: number;
  pathPointId?: number;
  setLoading: (value: boolean) => void;
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 14 },
};

const containerStyle = {
  width: '100%',
  height: '400px',
};

const center = {
  lat: 52.4342769,
  lng: 20.65679,
};

const PathPointForm: React.FC<Props> = ({ pathId, pathPointId, setLoading }) => {
  const [pathPoint, setPathPoint] = useState<PathPointType>();
  const [markerPosition, setMarkerPosition] = useState<google.maps.LatLng>();
  const [errors, setErrors] = useState<Record<string, Array<string>>>({});

  const [form] = Form.useForm<PathPointParams>();

  const navigate = useNavigate();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY ?? '',
  });

  useEffect(() => {
    if (pathPointId) {
      pathPointsService.getPathPoint(pathId, pathPointId).then((response) => {
        setPathPoint(response.path_point);
        form.setFieldsValue(response.path_point);
        setMarkerPosition(
          new google.maps.LatLng(response.path_point.latitude, response.path_point.longitude),
        );
        setLoading(false);
      });
    } else {
      setPathPoint(undefined);
      form.setFieldsValue({});
      setLoading(false);
    }
  }, [form, pathId, pathPointId, setLoading]);

  const catchErrors = (e: ErrorsType) => {
    if (e.statusCode === 422) {
      setErrors(e.errors || {});
    }
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  const onFinish = (values: PathPointParams) => {
    setLoading(true);
    setErrors({});

    const params = { ...values, latitude: markerPosition?.lat(), longitude: markerPosition?.lng() };

    if (pathPointId) {
      pathPointsService
        .updatePathPoint(pathId, pathPointId, params)
        .then(({ path_point }) => {
          notification.success({
            message: 'Zapisano punkt zwiedzania',
            description: (
              <>
                Dane punktu zwiedzania <strong>{path_point.name_pl}</strong> zostały zaktualizowane.
              </>
            ),
          });
          navigate('../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    } else {
      pathPointsService
        .createPathPoint(pathId, params)
        .then(({ path_point }) => {
          notification.success({
            message: 'Utworzono punkt zwiedzania',
            description: (
              <>
                Nowy punkt zwiedzania <strong>{path_point.name_pl}</strong> został dodany.
              </>
            ),
          });
          navigate('../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onFinishFailed = () => {
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  const onLoad = useCallback((map: google.maps.Map) => {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setTimeout(() => {
      map.setZoom(13);
    }, 100);
  }, []);

  const onUnmount = useCallback(() => {}, []);

  const onClick = ({ latLng }: google.maps.MapMouseEvent) => {
    if (latLng !== null) {
      setMarkerPosition(latLng);
    }
  };

  return (
    <Form
      {...layout}
      form={form}
      name="newPathForm"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Nazwa"
        name="name_pl"
        rules={[{ required: true, message: 'Nazwa są wymagane' }]}
        hasFeedback
        help={errors.name_pl?.[0]}
        validateStatus={errors.name_pl ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Nazwa (EN)"
        name="name_en"
        hasFeedback
        help={errors.name_en?.[0]}
        validateStatus={errors.name_en ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Opis"
        name="description_pl"
        hasFeedback
        help={errors.description_pl?.[0]}
        validateStatus={errors.description_pl ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Opis (EN)"
        name="description_en"
        hasFeedback
        help={errors.description_en?.[0]}
        validateStatus={errors.description_en ? 'error' : undefined}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Zdjęcie"
        name="image"
        hasFeedback
        help={errors.image?.[0]}
        validateStatus={errors.image ? 'error' : undefined}
      >
        <FileInput
          accept="image/jpeg,image/png"
          maxCount={1}
          defaultPreview={pathPoint?.thumb_image_url}
        />
      </Form.Item>

      <Form.Item label="Lokalizacja">
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onClick={onClick}
          >
            {markerPosition ? (
              <Marker
                draggable
                position={markerPosition}
                onDragEnd={({ latLng }: google.maps.MapMouseEvent) => {
                  if (latLng) {
                    setMarkerPosition(latLng);
                  }
                }}
              />
            ) : null}
          </GoogleMap>
        ) : null}
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Space>
          <Button className="mr-2" type="primary" htmlType="submit">
            Zapisz
          </Button>
          <Button className="mr-2" htmlType="button" onClick={() => form.resetFields()}>
            Reset
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
export default PathPointForm;
