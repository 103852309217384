import { useEffect, useState } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';

import './file-input.scss';

interface Props {
  defaultPreview?: string | null;
  accept?: string;
  maxCount?: number;
  onChange?: any;
}
const FileInput: React.FC<Props> = ({ accept, maxCount, onChange, defaultPreview }) => {
  const [fileType, setFileType] = useState<string>();
  const [imagePreview, setImagePreview] = useState<string | ArrayBuffer | null>();

  useEffect(() => {
    setImagePreview(defaultPreview);
    if (defaultPreview) {
      const filePath = defaultPreview.split('?').shift();
      const fileName = filePath?.split('/').pop();
      if (fileName?.endsWith('.mp4')) {
        setFileType('video/mp4');
      } else if (fileName?.endsWith('.mp3')) {
        setFileType('audio/mpeg');
      } else if (fileName?.endsWith('.jpg') || fileName?.endsWith('.jpeg')) {
        setFileType('image/jpeg');
      } else if (fileName?.endsWith('.png')) {
        setFileType('image/png');
      }
    }
  }, [defaultPreview]);

  const handleChange = ({ fileList }: UploadChangeParam) => {
    if (fileList && fileList.length === 1) {
      const reader = new FileReader();
      reader.onload = () => {
        const fileSrc = reader.result;
        setImagePreview(fileSrc);
        setFileType(fileList[0].type);
      };

      reader.readAsDataURL(fileList[0].originFileObj as File);
      onChange?.(fileList[0].originFileObj);
    } else if (fileList.length === 0) {
      setFileType('');
      setImagePreview(undefined);
      onChange?.(undefined);
    }
  };

  return (
    <div className="file-input">
      {imagePreview ? (
        <div className="file-input-preview">
          {fileType === 'video/mp4' ? (
            <div className="file-input-preview-card">
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video controls key={imagePreview as string}>
                <source src={imagePreview as string} type="video/mp4" />
              </video>
            </div>
          ) : null}
          {fileType === 'audio/mpeg' ? (
            <div className="file-input-preview-card">
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <audio controls key={imagePreview as string}>
                <source src={imagePreview as string} type="audio/mpeg" />
              </audio>
            </div>
          ) : null}
          {fileType === 'image/jpeg' || fileType === 'image/png' ? (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <div className="file-input-preview-card">
              <img src={imagePreview as string} alt="" />
            </div>
          ) : null}
        </div>
      ) : null}
      <Upload
        accept={accept}
        beforeUpload={() => false}
        maxCount={maxCount}
        onChange={handleChange}
      >
        {' '}
        <Button
          type="primary"
          icon={<UploadOutlined />}
          style={{ background: '#9b59b6', borderColor: '#8e44ad' }}
        >
          Zmień plik
        </Button>
      </Upload>
    </div>
  );
};

export default FileInput;
