import { Suspense } from 'react';

import { Routes, Route } from 'react-router-dom';

import CuisinesList from './cuisines-list';
import EditCuisine from './edit-cuisine';
import NewCuisine from './new-cuisine';

const CuisinesRoutes: React.FC = () => {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <Routes>
        <Route path="/new" element={<NewCuisine />} />
        <Route path="/:cuisineId/edit" element={<EditCuisine />} />
        <Route index element={<CuisinesList />} />
      </Routes>
    </Suspense>
  );
};

export default CuisinesRoutes;
