import { useEffect, useState } from 'react';

import { Button, Form, InputNumber, notification, Space } from 'antd';
import { useNavigate } from 'react-router-dom';

import picturesService, { type PictureParams } from '../../../../services/legends/pictures-service';
import ErrorsType from '../../../../typings/ErrorsType';
import FileInput from '../../../utils/file-input';

interface Props {
  pictureId?: number;
  setLoading: (value: boolean) => void;
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 14 },
};

const PictureForm: React.FC<Props> = ({ pictureId, setLoading }) => {
  const [defaultImagePl, setDefaultImagePl] = useState<string | null>();
  const [defaultImageEn, setDefaultImageEn] = useState<string | null>();
  const [errors, setErrors] = useState<Record<string, Array<string>>>({});

  const [form] = Form.useForm<PictureParams>();
  const navigate = useNavigate();

  useEffect(() => {
    if (pictureId) {
      picturesService.getPicture(pictureId).then((response) => {
        form.setFieldsValue({ duration: response.picture.duration });
        setDefaultImagePl(response.picture.thumb_image_pl_url);
        setDefaultImageEn(response.picture.thumb_image_en_url);
        setLoading(false);
      });
    } else {
      setDefaultImagePl(undefined);
      setDefaultImageEn(undefined);
    }
  }, [pictureId, form, setLoading]);

  const catchErrors = (e: ErrorsType) => {
    if (e.statusCode === 422) {
      setErrors(e.errors || {});
    } else {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw e;
    }
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  const onFinish = (values: PictureParams) => {
    setLoading(true);
    setErrors({});

    if (pictureId) {
      picturesService
        .updatePicture(pictureId, values)
        .then(({ picture }) => {
          notification.success({
            message: 'Zapisano zdjęcie do biografii fotografa',
            description: (
              <>
                Dane zdjęcia <strong>{picture.id}</strong> zostały zaktualizowane.
              </>
            ),
          });
          navigate('./../../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    } else {
      picturesService
        .createPicture(values)
        .then(({ picture }) => {
          notification.success({
            message: 'Utworzono zdjęcie do biografii fotografa',
            description: (
              <>
                Nowe zdjęcie <strong>{picture.id}</strong> zostało dodane do aplikacji Legendy.
              </>
            ),
          });
          navigate('./../');
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onFinishFailed = () => {
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  return (
    <Form
      {...layout}
      form={form}
      name="newPictureForm"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Zdjęcie (PL)"
        name="image_pl"
        hasFeedback
        help={errors.image_pl?.[0]}
        validateStatus={errors.image_pl ? 'error' : undefined}
      >
        <FileInput accept="image/jpeg,image/png" maxCount={1} defaultPreview={defaultImagePl} />
      </Form.Item>

      <Form.Item
        label="Zdjęcie (EN)"
        name="image_en"
        hasFeedback
        help={errors.image_en?.[0]}
        validateStatus={errors.image_en ? 'error' : undefined}
      >
        <FileInput accept="image/jpeg,image/png" maxCount={1} defaultPreview={defaultImageEn} />
      </Form.Item>

      <Form.Item
        label="Czas odtwarzania"
        name="duration"
        rules={[{ required: true, message: 'Tytuł jest wymagany' }]}
        hasFeedback
        help={errors.duration?.[0]}
        validateStatus={errors.duration ? 'error' : undefined}
      >
        <InputNumber min={1} addonAfter="sekund" />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Space>
          <Button className="mr-2" type="primary" htmlType="submit">
            Zapisz
          </Button>
          <Button className="mr-2" htmlType="button" onClick={() => form.resetFields()}>
            Reset
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
export default PictureForm;
