import { serialize } from 'object-to-formdata';

import type PhotographerType from '../../typings/photoBooth/PhotographerType';

import { deleteRequest, getRequest, postRequest, putRequest } from '../../api/api-client';

interface PhotographersResponse {
  photographers: Array<PhotographerType>;
}

interface PhotographerResponse {
  photographer: PhotographerType;
}

export interface PhotographerParams {
  title_pl: string;
  title_en: string;
  description_pl: string;
  description_en: string;
  cover_image: File | null;
  pictures: Array<
    | {
        id: number;
        description_pl: string;
        description_en: string;
        image: File | null;
      }
    | {
        id: number;
        _destroy?: boolean;
      }
  >;
}

const getPhotographers = async (): Promise<PhotographersResponse> => {
  return getRequest('/photobooth/photographers');
};

const getPhotographer = async (photographerId: number): Promise<PhotographerResponse> => {
  return getRequest(`/photobooth/photographers/${photographerId}`);
};

const createPhotographer = async (params: PhotographerParams): Promise<PhotographerResponse> => {
  return postRequest(`/photobooth/photographers`, serialize({ photographer: params }));
};

const updatePhotographer = async (
  photographerId: number,
  params: PhotographerParams,
): Promise<PhotographerResponse> => {
  return putRequest(
    `/photobooth/photographers/${photographerId}`,
    serialize({ photographer: params }),
  );
};
const deletePhotographer = async (photographerId: number): Promise<void> => {
  return deleteRequest(`/photobooth/photographers/${photographerId}`);
};

const reorderPhotographers = async (
  photographers: Array<{ id: number }>,
): Promise<PhotographersResponse> => {
  return postRequest('/photobooth/photographers/reorder', { photographers });
};

const photographersService = {
  getPhotographers,
  getPhotographer,
  createPhotographer,
  updatePhotographer,
  deletePhotographer,
  reorderPhotographers,
};

export default photographersService;
