import React, { useEffect, useState } from 'react';

import { Button, Form, notification, Space, Spin } from 'antd';

import moviesService, { MovieParams } from '../../../services/projection/movies-service';
import ErrorsType from '../../../typings/ErrorsType';
import MovieType from '../../../typings/MovieType';
import TitledCard from '../../layout-components/titled-card';
import FileInput from '../../utils/file-input';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 14 },
};

const MovieForm: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [movie, setMovie] = useState<MovieType>();
  const [form] = Form.useForm<MovieParams>();
  const [errors, setErrors] = useState<Record<string, Array<string>>>({});

  useEffect(() => {
    moviesService
      .getMovie()
      .then((response) => {
        form.setFieldsValue({});
        setMovie(response.movie);
      })
      .catch((e: ErrorsType) => {
        if (e.statusCode === 404) {
          form.setFieldsValue({});
          setMovie(undefined);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw e;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [form]);

  const catchErrors = (e: ErrorsType) => {
    if (e.statusCode === 422) {
      setErrors(e.errors || {});
    }
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  const onFinish = (values: MovieParams) => {
    setLoading(true);
    setErrors({});

    if (movie) {
      moviesService
        .updateMovie(movie.id, values)
        .then(() => {
          notification.success({
            message: 'Zapisano film',
            description: 'Dane filmu "Projekcja" zostały zaktualizowane.',
          });
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    } else {
      moviesService
        .createMovie(values)
        .then(() => {
          notification.success({
            message: 'Zapisano film',
            description: 'Dane filmu "Projekcja" zostały zaktualizowane.',
          });
        })
        .catch(catchErrors)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onFinishFailed = () => {
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  return (
    <Spin spinning={loading}>
      <TitledCard title="Edycja filmu Projekcja">
        <Form
          {...layout}
          form={form}
          name="newMovieForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Film"
            name="video_pl"
            hasFeedback
            help={errors.video_pl?.[0]}
            validateStatus={errors.video_pl ? 'error' : undefined}
          >
            <FileInput accept="video/mp4" maxCount={1} defaultPreview={movie?.video_pl_url} />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Space>
              <Button className="mr-2" type="primary" htmlType="submit">
                Zapisz
              </Button>
              <Button className="mr-2" htmlType="button" onClick={() => form.resetFields()}>
                Reset
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </TitledCard>
    </Spin>
  );
};
export default MovieForm;
