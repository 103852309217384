import React, { useEffect, useState } from 'react';

import { Button, Divider, Form, Input, notification, Select, Space, Spin } from 'antd';

import scheduleService, {
  dayNames,
  hoursList,
  ScheduleParams,
  WeekDayType,
} from '../../../services/schedule-service';
import ErrorsType from '../../../typings/ErrorsType';
import TitledCard from '../../layout-components/titled-card';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 14 },
};

const ScheduleForm: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm<ScheduleParams>();
  const [errors, setErrors] = useState<Array<Record<string, Array<string>>>>([]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    scheduleService.getSchedule().then((response) => {
      const workingHours = (Object.keys(dayNames) as WeekDayType[]).map(
        (dayName) => response.schedule[dayName],
      );
      form.setFieldsValue({ working_hours: workingHours });
      setLoading(false);
    });
  }, [form]);

  const onFinish = (values: ScheduleParams) => {
    setLoading(true);
    setErrors([]);

    scheduleService
      .updateSchedule(values)
      .then(() => {
        notification.success({
          message: 'Zapisano harmonogram',
          description: 'Harmonogram pracy wystawy został zaktualizowany.',
        });
      })
      .catch((e: ErrorsType) => {
        if (e.statusCode === 422) {
          notification.error({
            message: 'Dane nie mogły być zapisane',
          });
          setErrors((e.errors || []) as Array<Record<string, Array<string>>>);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw e;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = () => {
    notification.error({
      message: 'Dane nie mogły być zapisane',
    });
  };

  return (
    <Spin spinning={loading}>
      <TitledCard title="Edycja harmonogramu">
        <Form
          {...layout}
          form={form}
          name="scheduleForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={{ working_hours: [] }}
        >
          <Form.List name="working_hours">
            {(fields) =>
              fields.map((field) => (
                <React.Fragment key={field.key}>
                  <Divider />
                  <Form.Item label="Dzień tygodnia">
                    <Input
                      readOnly
                      value={
                        dayNames[
                          form.getFieldValue(['working_hours', field.key, 'weekday']) as WeekDayType
                        ]
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label="Godzina otwarcia wystawy"
                    name={[field.name, 'start_time']}
                    hasFeedback
                    help={errors[field.key]?.start_time?.[0]}
                    validateStatus={errors[field.key]?.start_time ? 'error' : undefined}
                  >
                    <Select>
                      {hoursList.map((option) => (
                        <Select.Option value={option.value}>{option.label}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Godzina zamknięcia wystawy"
                    name={[field.name, 'end_time']}
                    hasFeedback
                    help={errors[field.key]?.end_time?.[0]}
                    validateStatus={errors[field.key]?.end_time ? 'error' : undefined}
                  >
                    <Select>
                      {hoursList.map((option) => (
                        <Select.Option value={option.value}>{option.label}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </React.Fragment>
              ))
            }
          </Form.List>

          <Form.Item {...tailLayout}>
            <Space>
              <Button className="mr-2" type="primary" htmlType="submit">
                Zapisz
              </Button>
              <Button className="mr-2" htmlType="button" onClick={() => form.resetFields()}>
                Reset
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </TitledCard>
    </Spin>
  );
};
export default ScheduleForm;
