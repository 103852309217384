import { Suspense } from 'react';

import { Routes, Route } from 'react-router-dom';

import EditWord from './edit-word';
import NewWord from './new-word';
import WordsList from './words-list';

const DialoguesRoutes: React.FC = () => {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <Routes>
        <Route path="/new" element={<NewWord />} />
        <Route path="/:wordId/edit" element={<EditWord />} />
        <Route index element={<WordsList />} />
      </Routes>
    </Suspense>
  );
};

export default DialoguesRoutes;
